<template>

    <goh-widget>
        <div class="goh-reset-password">

            <template v-if="passwordReset">
                <span class="title">Wachtwoord is opnieuw ingesteld</span>
                <span class="subtitle">Je nieuwe wachtwoord is successvol ingesteld.</span>
            </template>

            <template v-else>
                <span class="title">Wachtwoord opnieuw instellen</span>
                <span class="subtitle">Je kunt hier je nieuwe wachtwoord instellen. Vul een nieuw wachtwoord in, en herhaal deze.</span>
                <ui-form ref="resetPasswordForm" @submit="submitResetPasswordForm">
                    <ui-grid class="input">
                        <div class="col-12">
                            <ui-form-input
                                icon="bx bx-key" v-model="newPassword" name="password"
                                placeholder="Nieuw wachtwoord*" label="Nieuw wachtwoord"
                                rules="required|password" type="password"
                            ></ui-form-input>
                        </div>
                        <div class="col-12">
                            <ui-form-input
                                icon="bx bx-key" v-model="confirmNewPassword" name="passwordConfirm"
                                placeholder="Herhaal wachtwoord*"
                                hint="* Minimaal 8 tekens, 1 speciaal teken en 1 hoofdletter"
                                label="Herhaal wachtwoord" type="password"
                                :rules="{ confirmed: '@password' }"
                            ></ui-form-input>
                        </div>
                    </ui-grid>
                    <ui-grid class="submit">
                        <div class="col-12 col-align-center">
                            <ui-button color="primary" :loading="resettingPassword" @click="$refs.resetPasswordForm.submit()">Opslaan</ui-button>
                        </div>
                    </ui-grid>
                </ui-form>
            </template>
        </div>
    </goh-widget>

</template>

<script>

import { Options, Vue } from 'vue-class-component';
import { Button, Form, FormInput, Grid } from '@wittignl/js-ui';
import { getGraphQLError } from '@wittignl/js-core';

import Widget from './Widget.vue';
import ApiErrorType from 'common/js/enum/ApiErrorType';
import AuthMutations from '../../mutation/AuthMutations';

export default Options({
    name: 'goh-reset-password',
    components: {
        'goh-widget': Widget,
        'ui-button': Button,
        'ui-form': Form,
        'ui-form-input': FormInput,
        'ui-grid': Grid
    }
})(
    class ResetPassword extends Vue {

        passwordReset = false;

        newPassword = null;
        confirmNewPassword = null;
        resettingPassword = false;

        token = null;

        created(){

            let urlParams = new URLSearchParams(window.location.search);
            this.token = urlParams.get('token');
        }

        async submitResetPasswordForm(){

            this.resettingPassword = true;

            try {

                await AuthMutations.resetPassword(this.$apollo.getClient(), { token: this.token, password: this.newPassword });
                this.passwordReset = true;
            }
            catch(e){

                const error = getGraphQLError(e);

                switch(error.type){

                    case ApiErrorType.NOT_FOUND:
                        this.$toast.error('Het wachtwoord vergeten verzoek is niet gevonden.');
                        break;

                    default:
                        this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
                        break;
                }
            }

            this.resettingPassword = false;
        }
    }
);

</script>
<style src="../../../scss/component/widget/Authentication.scss" lang="scss"></style>