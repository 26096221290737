<template>

    <goh-widget>

        <div class="goh-pay" v-spinner="loading">

            <div v-if="error" class="error">{{ error }}</div>

            <div v-else-if="paymentSuccess" class="payment">

                <div class="intro">
                    <span class="title">Bedankt voor je betaling!</span>
                    <span class="subtitle">Je kunt dit venster nu sluiten</span>
                </div>
            </div>

            <div v-else class="payment">

                <div class="intro">
                    <span class="title">Betaal je lidmaatschap</span>
                </div>

                <ui-panel class="payment-info">
                    <div>
                        <span class="label">Jouw lidmaatschap</span>
                        <span class="value">{{ userSubscription?.membership?.title }}</span>
                    </div>
                    <div>
                        <span class="label">Nu te betalen</span>
                        <span class="value price">&euro; {{ $f.number(resolvedPrice, 2, 2) }}</span>
                    </div>
                </ui-panel>

                <div class="goh-error-block" v-if="pendingTransaction?.paymentError">
                    <span class="title">De betaling is mislukt.</span>
                    <p>{{ $f.paymentError(pendingTransaction.paymentError) }}</p>
                </div>

                <ui-panel>
                    <goh-payment-method-selector ref="methodSelector"
                        :disabled="hasSavingSpinner"
                        v-model="selectedPayment" :payment-methods="paymentMethods"
                    ></goh-payment-method-selector>
                </ui-panel>
                <ui-button class="button-save" color="primary" @click="submit" :loading="hasSavingSpinner" :disabled="!selectedPayment">
                    Betalen
                </ui-button>
            </div>

        </div>

    </goh-widget>

</template>

<script>

import { Vue, Options } from 'vue-class-component';

import { createQuery, getGraphQLError } from '@wittignl/js-core';
import { Grid, Button, Panel } from '@wittignl/js-ui';

import Widget from './Widget.vue';
import PaymentMethodSelector from '../ui/PaymentMethodSelector.vue';

import FindPaymentMethods from '../../../gql/query/Pay/FindPaymentMethods.gql';
import FindTransactionByToken from '../../../gql/query/Pay/FindTransactionByToken.gql';
import FindUserSubscriptionByToken from '../../../gql/query/Pay/FindUserSubscriptionByToken.gql';

import SubscriptionMutations from '../../mutation/SubscriptionMutations'
import TransactionState from 'common/js/enum/TransactionState';
import ApiErrorType from 'common/js/enum/ApiErrorType';
import UserSubscriptionState from 'common/js/enum/UserSubscriptionState';
import PaymentMethod from 'common/js/enum/PaymentMethod';
import TransactionType from 'common/js/enum/TransactionType';

export default Options({
    name: 'goh-pay',
    components: {
        [Widget.name]: Widget,
        [PaymentMethodSelector.name]: PaymentMethodSelector,
        [Grid.name]: Grid,
        [Panel.name]: Panel,
        [Button.name]: Button
    },
    apollo: {

        paymentMethods: createQuery(FindPaymentMethods),

        userSubscription: createQuery(FindUserSubscriptionByToken, {

            skip() {
                return !this.subscriptionToken;
            },

            variables() {

                return {
                    token: this.subscriptionToken
                }
            }
        }),

        pendingTransaction: createQuery(FindTransactionByToken, {

            loadingKey: 'loadingTransaction',

            skip(){
                return !this.token
            },

            variables(){

                return {
                    token: this.token
                }
            },

            response(transaction){

                this.selectedPayment = {
                    method: transaction.paymentMethod,
                    issuer: transaction.paymentIssuer
                };

                switch(transaction.state){

                    case TransactionState.PENDING:
                        setTimeout(() => this.$apollo.queries.pendingTransaction.refetch(), 1000);
                        break;

                    case TransactionState.COMPLETED:
                        this.reportPaymentSuccess();
                        break;
                }
            }
        })
    }
})(
    class Pay extends Vue {

        loading = 0;
        loadingTransaction = 0;
        paymentSuccess = false;

        paying = false;

        selectedPayment = null;
        error = null;

        token = null;
        subscriptionToken = null;
        customAmount = null;

        created(){

            let urlParams = new URLSearchParams(window.location.search);
            this.token = urlParams.get('token');

            const subTokenRaw = urlParams.get('subtoken');
            if(subTokenRaw){

                const [subtoken, amount] = atob(subTokenRaw).split(':');

                this.subscriptionToken = subtoken;
                this.customAmount = amount ? parseFloat(amount) : null
            }

            if(!this.subscriptionToken){
                this.error = "Uw betaallink is helaas ongeldig. Neem contact op als deze foutmelding zich blijft voordoen.";
            }
        }

        async submit(){

            if(!this.$refs.methodSelector.validate()){
                this.$toast.validation();
                return;
            }

            this.paying = true;

            try {

                const cardToken = await this.$mollie.createToken();

                const response = await SubscriptionMutations.paySubscription(this.$apollo, {
                    token: this.subscriptionToken,
                    amount: this.customAmount,
                    payment: {
                        ...(this.selectedPayment?.method === PaymentMethod.CREDIT_CARD ? { cardToken: cardToken.token }  : {}),
                        ...this.selectedPayment
                    }
                });

                window.location.replace(response.data.paySubscription);
            }
            catch(e){

                this.logger.error(e);
                const error = getGraphQLError(e);

                switch(error?.type){

                    case ApiErrorType.SUBSCRIPTION_ACTIVE_ERROR:
                        this.error = 'De betaling kan niet worden voltooid, neem contact op voor verdere afhandeling.';
                        break;

                    case ApiErrorType.NOT_FOUND:
                        this.error = 'De link is ongeldig';
                        break;

                    default:
                        this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
                        break;
                }
            }

            this.paying = true;
        }

        reportPaymentSuccess(){

            if(this.userSubscription.state === UserSubscriptionState.ON_HOLD || this.userSubscription.state === UserSubscriptionState.ACTIVE){
                window.location.replace("https://gekophaken.nl/betalen-succes/");
            }
            else if(this.userSubscription.state === UserSubscriptionState.PENDING) {
                window.location.replace(this.userSubscription.membership.thanksUrl);
            }
            else {
                this.paymentSuccess = true;
            }
        }

        get resolvedPrice(){

            if(this.customAmount){
                return this.customAmount;
            }
            else {
                return this.userSubscription?.actualPrice || this.userSubscription?.membership?.paymentPrice;
            }
        }

        get hasSavingSpinner(){

            return this.paying || this.pendingTransaction?.state === TransactionState.PENDING;
        }
    }
);

</script>

<style lang="scss" src="../../../scss/component/widget/Pay.scss"></style>