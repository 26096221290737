import bootstrap from './bootstrap';
import validateSession from '@/js/util/validateSession';

import { createApp } from 'vue';
import App from './component/App.vue';

import "../scss/app.scss";


const run = async () => {

    const app = createApp(App);

    // Bootstrap
    bootstrap(app);

    // Validate Session
    const skipSessionValidation = (document.currentScript.getAttribute('data-skip-session-validation') === 'true');
    if (!skipSessionValidation) {

        await validateSession(app.config.globalProperties.$auth);
    }

    return app;
};

run().then((app) => {

    app.mount('#app');
    document.getElementsByClassName('full-screen-loader')[0]?.classList.remove('cloak');
});