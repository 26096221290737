import ev from "../util/enumValue";

enum TransactionState {
    PENDING = ev('PENDING', 0),
    COMPLETED = ev('COMPLETED', 1),
    FAILED = ev('FAILED', 2),
    REFUNDED = ev('REFUNDED', 3),
    PROCESSING = ev('PROCESSING', 4),
    ON_HOLD = ev('ON_HOLD', 5),
    CANCELLED = ev('CANCELLED', 6),
    CHARGED_BACK = ev('CHARGED_BACK', 7),

    EXPIRED = ev('EXPIRED', 8)
}

export default TransactionState;