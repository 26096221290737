<template>

    <goh-widget>

        <div class="goh-forgot-password" v-if="!submitted">
            <span class="title">Wachtwoord vergeten</span>
            <span class="subtitle">Vul het e-mailadres in waarmee je bij ons bekend bent. We sturen je dan een e-mail met instructies om een nieuw wachtwoord aan te maken. Geen e-mail ontvangen? Kijk altijd even in je spam-folder!</span>
            <ui-form ref="forgotPasswordForm" @submit="submitForgotPasswordForm">
                <ui-grid class="input">
                    <div class="col-12">
                        <ui-form-input
                            icon="bx bx-envelope" v-model="email"
                            placeholder="E-mailadres" name="E-mailadres"
                            rules="required|email"
                            hint="Weet je niet meer met welk e-mailadres je bent geregistreerd? Neem dan contact met ons op, we helpen je graag."
                        ></ui-form-input>
                    </div>
                </ui-grid>
                <ui-grid class="submit">
                    <div class="col-12 col-align-center">
                        <ui-button :loading="submittingForgotPassword" color="primary" @click="$refs.forgotPasswordForm.submit()">Verzenden</ui-button>
                    </div>
                </ui-grid>
            </ui-form>
        </div>

        <div class="goh-forgot-password" v-else>
            <span class="title">Gelukt!</span>
            <span class="subtitle">Er is een e-mail naar je onderweg met daarin een knop om je wachtwoord te resetten.</span>
        </div>

    </goh-widget>

</template>

<script>

import { Options, Vue } from 'vue-class-component';
import Widget from './Widget.vue';
import { Button, Form, FormInput, Grid } from '@wittignl/js-ui';
import AuthMutations from '../../mutation/AuthMutations';
import { getGraphQLError } from '@wittignl/js-core';

export default Options({
    name: 'goh-forgot-password',
    components: {
        'goh-widget': Widget,
        'ui-button': Button,
        'ui-form': Form,
        'ui-form-input': FormInput,
        'ui-grid': Grid
    }
})(
    class ForgotPassword extends Vue {

        submitted = false;

        email = null;
        submittingForgotPassword = false;

        async submitForgotPasswordForm(){

            this.submittingForgotPassword = true;

            try {

                await AuthMutations.forgotPassword(this.$apollo.getClient(), { email: this.email });
                this.submitted = true;
            }
            catch(e){

                const error = getGraphQLError(e);

                switch(error.type){

                    default:
                        this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
                        break;
                }
            }

            this.submittingForgotPassword = false;
        }
    }
);

</script>
<style src="../../../scss/component/widget/Authentication.scss" lang="scss"></style>