import di from 'di';
import {
    boolFilter,
    concatFilter,
    dateFilter,
    emptyFilter,
    numberFilter,
    truncateFilter,
    ucFirstFilter,
    upperCaseFilter,
    countryFilter,
    FilterPlugin
} from '@wittignl/js-core';
import paymentMethodFilter from 'common/js/filter/paymentMethod';
import paymentErrorFilter from 'common/js/filter/paymentError';
import paymentFrequencySuffixFilter from 'common/js/filter/paymentFrequencySuffix';
import paymentFrequencyFilter from 'common/js/filter/paymentFrequency';
import paymentFrequencyFromFilter from 'common/js/filter/paymentFrequencyFrom';
import transactionStateFilter from 'common/js/filter/transactionState';
import discountDurationFilter from 'common/js/filter/discountDuration';
import paymentDurationFilter from "common/js/filter/paymentDuration";

export default function(app){

    const plugin = new FilterPlugin();

    plugin

        // Core
        .filter('bool', boolFilter)
        .filter('concat', concatFilter)
        .filter('date', dateFilter)
        .filter('empty', emptyFilter)
        .filter('number', numberFilter)
        .filter('truncate', truncateFilter)
        .filter('ucFirst', ucFirstFilter)
        .filter('upperCase', upperCaseFilter)
        .filter('country', countryFilter)

        // Common
        .filter('paymentMethod', paymentMethodFilter)
        .filter('paymentError', paymentErrorFilter)
        .filter('paymentFrequencySuffix', paymentFrequencySuffixFilter)
        .filter('paymentFrequency', paymentFrequencyFilter)
        .filter('paymentFrequencyFrom', paymentFrequencyFromFilter)
        .filter('transactionState', transactionStateFilter)
        .filter('discountDuration', discountDurationFilter)
        .filter('paymentDuration', paymentDurationFilter)

    // App

    app.use(plugin, {
        i18n: di.get('i18n')
    })
}