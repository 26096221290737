import ev from '../util/enumValue';

enum PaymentError {
    UNKNOWN = ev('UNKNOWN', 0),
    PAYMENT_CANCELED = ev('PAYMENT_CANCELED', 1),
    PAYMENT_EXPIRED = ev('PAYMENT_EXPIRED', 2),
    AUTHENTICATION_FAILED = ev('AUTHENTICATION_FAILED', 3),
    CARD_EXPIRED = ev('CARD_EXPIRED', 4),
    INACTIVE_CARD = ev('INACTIVE_CARD', 5),
    INSUFFICIENT_FUNDS = ev('INSUFFICIENT_FUNDS', 6),
    INVALID_CARD_HOLDER_NAME = ev('INVALID_CARD_HOLDER_NAME', 7),
    INVALID_CARD_NUMBER = ev('INVALID_CARD_NUMBER', 8),
    INVALID_CARD_TYPE  = ev('INVALID_CARD_TYPE', 9),
    INVALID_CVC = ev('INVALID_CVC', 10),
    POSSIBLE_FRAUD = ev('POSSIBLE_FRAUD', 11),
    ISSUER_REFUSED = ev('ISSUER_REFUSED', 12),
    MOLLIE_CHARGE_FAILED = ev('MOLLIE_CHARGE_FAILED', 13)
}

export default PaymentError;