<template>
    <div class="goh-dropdown">

        <template v-if="isMobile">

            <ui-form-item class="native" v-bind="$componentProps('ui-form-item')" :label="label" :name="name" :rules="rules" v-slot="{ errors }">
                
                <i class="icon bx bx-chevron-down" ref="chevron"></i>

                <select :value="modelValue" @change="onChange" :class="{ 'error': hasError(errors), 'placeholder': modelValue == null }">

                    <option class="placeholder" value="" disabled selected hidden>
                        {{ placeholder }}
                    </option>
                    <option v-for="option in options" :key="option.value" :value="option.value" :disabled="disabled">
                        {{ option.label }}
                    </option>
                </select>

            </ui-form-item>

        </template>
        <template v-else>

            <ui-form-dropdown :placeholder="placeholder" :label="label" :name="name" :rules="rules" :searchable="searchable"
                :options="options" :disabled="disabled" @blur="blur" :modelValue="modelValue"
                @update:modelValue="onChange" />

        </template>
    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';

import { FormDropdown, FormItem } from '@wittignl/js-ui';
import { isArray } from 'lodash';
import { componentPropsMixin } from '@wittignl/js-core';


export default Options({

    name: 'goh-dropdown',
    props: ['modelValue', 'placeholder', 'label', 'options', 'name', 'rules', 'searchable', 'disabled', 'blur'],
    components: {
        [FormDropdown.name]: FormDropdown,
        [FormItem.name]: FormItem,
    },
    mixins: [
    componentPropsMixin
    ]
})(
    class Dropdown extends Vue {

        onChange(event) {

            this.$emit('update:modelValue', event.target?.value || event);
        }
        hasError(error) {
            return isArray(error) ? error.length : !!error;
        }

        get isMobile() {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }
);

</script>
<style lang="scss" src="../../../scss/component/ui/Dropdown.scss"></style>