<template>
    <div class="goh-profile-account" v-spinner="loading">
        <template v-if="viewer != null">
            <div class="intro">
                <h2 class="section-title">Persoonlijke gegevens</h2>
                <p>Dit zijn jouw persoonlijke gegevens zoals ze bij ons bekend zijn. Wil je iets aanpassen? Doe dat dan hieronder en klik op ‘opslaan’.</p>
            </div>

            <ui-form ref="updateUserForm" @submit="submitUpdateUserForm">
                <ui-grid>
                    <div class="col-12">
                        <ui-form-input
                            placeholder="E-mailadres" label="E-mailadres" title="E-mailadres"
                            name="email" rules="required" disabled
                            v-model="viewer.email"
                        />
                        <p class="hint-link">Wil je jouw e-mailadres aanpassen?
                            <a href="https://gekophaken.nl/contact/" target="_blank">Stuur ons dan even een mailtje</a>, dan nemen we contact met je op.
                        </p>
                    </div>
                    <div class="col-6">
                        <ui-form-input
                            placeholder="Voornaam" label="Voornaam" title="Voornaam" name="firstName" rules="required"
                            v-model="pendingUser.firstName"
                        />
                    </div>
                    <div class="col-6">
                        <ui-form-input
                            placeholder="Achternaam" label="Achternaam" title="Achternaam" name="lastName" rules="required"
                            v-model="pendingUser.lastName"
                        />
                    </div>
                    <div class="col-12">
                        <ui-form-input placeholder="Telefoonnummer" label="Telefoonnummer" title="Telefoonnummer" name="phone" rules="required" v-model="pendingUser.phone"/>
                    </div>
                    <div class="col-6">
                        <ui-form-input placeholder="Straat" label="Straat" title="Straat " name="street" rules="required" v-model="pendingUser.street"/>
                    </div>
                    <div class="col-4">
                        <ui-form-input
                            placeholder="Huisnummer" label="Huisnummer"
                            title="Huisnummer" name="houseNumber" rules="required"
                            maxlength="10" v-model="pendingUser.houseNumber" :cleave="{ numeral: true, delimiter: '' }"
                        />
                    </div>
                    <div class="col-2">
                        <ui-form-input placeholder="Toev." label="Toev." title="Toev." name="houseNumberAddition" v-model="pendingUser.houseNumberAddition" rules="max:10"/>
                    </div>
                    <div class="col-12">
                        <ui-form-input placeholder="Woonplaats" label="Woonplaats" title="Woonplaats" name="city" rules="required" v-model="pendingUser.city"/>
                    </div>
                    <div class="col-4">
                        <ui-form-input placeholder="Postcode" label="Postcode" title="Postcode" name="postalCode" rules="required" v-model="pendingUser.postalCode"/>
                    </div>
                    <div class="col-8">
                        <ui-form-dropdown
                            placeholder="Land" label="Land" title="Land" name="country" rules="required" searchable
                            :options="countryOptions" v-model="pendingUser.country"
                        />
                    </div>
                    <div class="col-12">
                        <ui-form-date-input
                            name="dateOfBirth" title="Geboortedatum" icon="bx bx-calendar"
                            v-model="pendingUser.dateOfBirth" input/>

                        <div class="hint">
                            <p>We gebruiken je geboortedatum alleen om een leuk cadeautje te sturen op je verjaardag 🎉</p>
                            <p>Ben je binnen nu en twee weken jarig? Dan is het helaas te kort dag om jouw cadeautje nog automatisch en op tijd te versturen. Stuur ons even een berichtje en dan zorgen we dat je jouw verjaardagscadeautje toch nog krijgt!
                                <a href="https://gekophaken.nl/contact/">Klik hier om naar het contactformulier te gaan</a>.
                            </p>
                        </div>
                    </div>
                </ui-grid>
                <ui-grid>
                    <div class="col-12 col-align-left-x buttons">
                        <ui-button color="primary" :loading="submittingUserUpdate" @click="$refs.updateUserForm.submit()">Opslaan</ui-button>
                    </div>
                </ui-grid>
            </ui-form>
        </template>
    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';
import { pick } from 'lodash';

import { Button, Form, FormDropdown, FormInput, FormDateInput, Grid } from '@wittignl/js-ui';
import { createQuery } from '@wittignl/js-core';

import ViewerQuery from '../../../../gql/query/Viewer.gql';
import AccountMutations from '../../../mutation/AuthMutations';
import { getCountryOptions } from 'common/js/util/country';

export default Options({
    name: 'goh-profile-account',
    components: {
        [Grid.name]: Grid,
        [Button.name]: Button,
        [Form.name]: Form,
        [FormInput.name]: FormInput,
        [FormDateInput.name]: FormDateInput,
        [FormDropdown.name]: FormDropdown
    },

    apollo: {

        membership: createQuery(ViewerQuery, {

            response(viewer){

                this.viewer = viewer;
                this.pendingUser = pick(viewer, Object.keys(this.pendingUser));
            },

            emptyResponse(){

                //window.location.replace('/');
            }
        })
    }
})(
    class ProfileAccount extends Vue {

        viewer = null;
        pendingUser = {

            firstName: null,
            lastName: null,

            phone: null,

            street: null,
            houseNumber: null,
            houseNumberAddition: null,

            postalCode: null,
            city: null,
            country: null,

            dateOfBirth: null
        };

        loading = 0;
        submittingUserUpdate = false;

        get countryOptions(){

            return getCountryOptions(this.$f.country);
        }

        async submitUpdateUserForm(){

            this.submittingUserUpdate = true;

            try {

                await AccountMutations.updateAccount(this.$apollo, {
                    input: this.pendingUser
                });

                this.$toast.success('Je gegevens zijn succesvol opgeslagen.');
            }
            catch(e){

                this.logger.error(e);
                this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
            }

            this.submittingUserUpdate = false;
        }
    }
);

</script>

<style scoped lang="scss" src="../../../../scss/component/ui/profile/ProfileAccount.scss"></style>