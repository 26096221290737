import di from 'di';

import { LOG_LEVELS, Logger, ConsoleStream } from '@wittignl/js-core'

export default function(app){

    const logger = new Logger();
    logger.stream('console', new ConsoleStream(), LOG_LEVELS.LOG);

    app.use(logger);
    di.set('logger', logger);
}