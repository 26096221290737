<template>

    <div class="goh-widget">
        <slot></slot>
    </div>

</template>

<script>

import { Vue, Options } from "vue-class-component";


export default Options({

    name: "goh-widget"
})(
    class Widget extends Vue {

    }
);

</script>
<style src="../../../scss/component/widget/Widget.scss" lang="scss"></style>