export class MollieService {

    components = {};

    constructor(profile, { debug }) {

        this.client = new window.Mollie(profile, {
            locale: 'nl_NL',
            testmode: debug
        });
    }

    createToken(){

        return this.client.createToken();
    }

    getComponent(type){

        if(this.components[type]){
            return this.components[type];
        }

        const comp = this.client.createComponent(type, {
            styles: {
                base: {
                    color: '#333333',
                    fontSize: '14px',
                    '::placeholder' : {
                        color: '#999999'
                    }
                },
                invalid: {
                    color: '#F84646',
                }
            }
        });

        this.components[type] = comp;
        return comp;
    }
}