import di from 'di'
import { MollieService } from '@gekophaken/widgets';

export default function(app){

    di.factory('mollie', () => {

        const config = di.get('config');
        return new MollieService(config.mollie.profile, !!config.debug);
    });
}