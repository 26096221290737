import ev from "../util/enumValue";

enum UserSubscriptionState {
    PENDING = ev('PENDING', 0),
    ACTIVE = ev('ACTIVE', 1),
    FAILED = ev('FAILED', 2),
    CANCELLED = ev('CANCELLED', 3),
    ON_HOLD = ev('ON_HOLD', 4)
}

export default UserSubscriptionState;