<template>

    <goh-widget>
        <div class="goh-login" v-if="!startingOAuth">
            <span class="title">Inloggen</span>

            <ui-form ref="loginForm" @submit="submit">

                <ui-grid class="input">

                    <div class="col-12">
                        <ui-form-input
                            icon="bx bx-user" name="username" :disabled="shouldDisable"
                            placeholder="E-mailadres" label="E-mailadres"
                            v-model.trim="username" rules="required|email"
                        ></ui-form-input>
                    </div>

                    <div class="col-12 goh-password-field">

                        <ui-form-input
                            :type="showPassword ? 'text' : 'password'" name="password" icon="bx bx-key"
                            placeholder="Wachtwoord" label="Wachtwoord"
                            v-model.trim="password" rules="required" :disabled="shouldDisable"
                        ></ui-form-input>


                        <ui-button type="quiet" class="password-hide" color="primary"
                            @click="showPassword = !showPassword"
                            :icon="showPassword ? 'bx bx-hide' : 'bx bx-show'"
                            v-tooltip="showPassword ? 'Verberg wachtwoord' : 'Laat wachtwoord zien'"
                        ></ui-button>
                    </div>

                    <div class="col-6 col-align-center-y">
                        <ui-form-item>
                            <ui-checkbox :disabled="shouldDisable" v-model="rememberMe">Onthoud mij</ui-checkbox>
                        </ui-form-item>
                    </div>

                    <div class="col-6 col-align-right-x col-align-center-y">
                        <a href="/wachtwoord-vergeten" class="forgot-password">Wachtwoord vergeten</a>
                    </div>

                </ui-grid>

                <ui-grid class="submit">
                    <div class="col-12 col-align-center">
                        <ui-button color="primary" @click="$refs.loginForm.submit()" :loading="shouldDisable">Inloggen</ui-button>
                    </div>
                </ui-grid>

            </ui-form>
        </div>

        <div v-else v-spinner="true"></div>

        <form ref="oauthForm" method="post" :action="$oauthAuthorizeUrl">
            <input type="hidden" name="client_id" :value="clientId">
            <input type="hidden" name="scope" :value="scope">
            <input type="hidden" name="redirect_uri" :value="redirectUri">
            <input type="hidden" name="response_type" :value="responseType">
            <input type="hidden" name="state" :value="state">
            <input type="hidden" name="token" :value="token">
        </form>

    </goh-widget>

</template>

<script>

import { Vue, Options } from 'vue-class-component';
import { Button, Checkbox, Form, FormItem, FormInput, Grid } from '@wittignl/js-ui';

import ApiErrorType from 'common/js/enum/ApiErrorType';

import Widget from './Widget.vue';
import EmailAccountType from '../../auth/EmailAccountType';


export default Options({

    name: 'goh-login',

    components: {

        [Widget.name]: Widget,
        [Grid.name]: Grid,

        [Button.name]: Button,
        [Checkbox.name]: Checkbox,

        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [FormInput.name]: FormInput
    }
})(
    class Login extends Vue {

        submitting = false;
        startingOAuth = false;

        username = null;
        password = null;
        rememberMe = false;

        clientId = null;
        scope = null;
        redirectUri = null;
        responseType = null;
        state = null;

        showPassword = false;

        created(){

            let urlParams = new URLSearchParams(window.location.search);
            this.clientId = urlParams.get('client_id');
            this.scope = urlParams.get('scope');
            this.redirectUri = urlParams.get('redirect_uri');
            this.responseType = urlParams.get('response_type');
            this.state = urlParams.get('state');

            if (this.clientId == null || this.redirectUri == null) {

                this.startOAuthFlow();
            }
        }

        mounted() {

            if (this.token != null && !this.startingOAuth) {

                this.startingOAuth = true;
                this.$refs.oauthForm.submit();
            }
        }

        startOAuthFlow(){

            this.startingOAuth = true;
            window.location.href = this.$oauthRedirectUrl;
        }

        get shouldDisable(){

            return this.submitting || this.startingOAuth;
        }

        get token(){

            return this.$auth.session?.token;
        }

        async submit(){

            if(!this.username || !this.password){

                this.$toast.error('Voer uw gebruikersnaam en wachtwoord in');
                return;
            }

            this.submitting = true;
            this.logger.info(`Logging in ${this.username}`);

            try {

                await this.$auth.login(EmailAccountType.NAME, {
                    username: this.username,
                    password: this.password,
                    remember: this.rememberMe
                });

                if(this.redirectUri != null){

                    this.$refs.oauthForm.submit();
                }
                else {

                    window.location.href = '/profiel';
                }
            }
            catch(e){

                this.logger.error('Error logging in', e);
                const errorType = e.response?.data?.errors[0]?.type;

                switch(errorType){

                    case ApiErrorType.USER_INACTIVE_ERROR:
                        this.$toast.error('Je account is inactief, neem contact op.');
                        break;

                    default:
                        this.$toast.error('Onjuiste inloggegevens, probeer het opnieuw.');
                        break;
                }

                this.submitting = false;
            }
        }
    }
);

</script>
<style src="../../../scss/component/widget/Authentication.scss" lang="scss"></style>