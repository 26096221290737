export default function(input){

    switch(input){

        //@TODO: ENUM
        case 1: return '/ maand';
        case 6: return '/ halfjaar';
        case 12: return '/ jaar';

        default: return `/ ${input} maanden`;
    }
}