<template>
    <div class="goh-profile-change-password">
        <div class="intro">
            <h2>Wachtwoord wijzigen</h2>
            <p>Wil je je wachtwoord wijzigen? Vul dan hieronder je huidige wachtwoord in, kies een nieuw wachtwoord en klik op ‘opslaan’. Weet je je wachtwoord niet meer? Log dan uit, en ga naar Wachtwoord vergeten om het opnieuw in te stellen.</p>
        </div>

        <ui-form ref="changePasswordForm" @submit="submitChangePasswordForm">
            <ui-grid>
                <div class="col-12">
                    <ui-form-input
                        placeholder="Huidig wachtwoord" label="Huidig wachtwoord" title="Huidig wachtwoord"
                        name="currentPassword" rules="required" v-model="currentPassword"
                        type="password"
                    />
                </div>
                <div class="col-6">
                    <ui-form-input
                        v-model="newPassword" name="newPassword"
                        placeholder="Nieuw wachtwoord" label="Nieuw wachtwoord" title="Nieuw wachtwoord *"
                        hint="Let op: een veilig wachtwoord moet altijd uit minimaal 8 tekens, 1 hoofdletter en 1 cijfer of speciaal karakter bestaan."
                        rules="required|password" type="password"
                    ></ui-form-input>
                </div>
                <div class="col-6">
                    <ui-form-input
                        v-model="newPasswordConfirmation" name="newPasswordConfirmation"
                        placeholder="Nieuw wachtwoord herhalen"
                        label="Nieuw wachtwoord herhalen" title="Nieuw wachtwoord herhalen *" type="password"
                        :rules="{ confirmed: '@newPassword' }"
                    ></ui-form-input>
                </div>
            </ui-grid>

            <div class="buttons">
                <ui-button color="primary" :loading="submittingChangePassword" @click="$refs.changePasswordForm.submit()">Opslaan</ui-button>
            </div>
        </ui-form>
    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';
import { getGraphQLError } from '@wittignl/js-core';
import { Button, Form, FormInput, Grid } from '@wittignl/js-ui';

import AuthMutations from '../../../mutation/AuthMutations';
import ApiErrorType from 'common/js/enum/ApiErrorType';

export default Options({
    name: 'goh-profile-change-password',
    components: {
        [Grid.name]: Grid,
        [Button.name]: Button,
        [Form.name]: Form,
        [FormInput.name]: FormInput
    }
})(
    class ProfileChangePassword extends Vue {

        currentPassword = null;

        newPassword = null;
        newPasswordConfirmation = null;

        submittingChangePassword = false;

        async submitChangePasswordForm() {

            this.submittingChangePassword = true;

            try {

                await AuthMutations.changePassword(this.$apollo, {

                    input: {
                        currentPassword: this.currentPassword,
                        newPassword: this.newPassword
                    }
                });

                this.currentPassword = null;
                this.newPassword = null;
                this.newPasswordConfirmation = null;

                this.$toast.success('Je wachtwoord is successvol gewijzigd');
            }
            catch(e) {

                this.logger.error(e);
                const error = getGraphQLError(e);

                switch(error?.type) {

                    case ApiErrorType.PASSWORD_INVALID:
                        this.$toast.error('Het ingevoerde huidige wachtwoord is niet juist');
                        break;

                    case ApiErrorType.PASSWORD_WEAK:
                        this.$toast.error('Je nieuwe wachtwoord is te zwak');
                        break;

                    default:
                        this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
                        break;
                }
            }

            this.submittingChangePassword = false;
        }
    }
);

</script>

<style scoped lang="scss" src="../../../../scss/component/ui/profile/ProfileChangePassword.scss"></style>