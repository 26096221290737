import mutate from "../util/mutate";

import ForgotPassword from '../../gql/mutation/Auth/ForgotPassword.gql';
import ResetPassword from '../../gql/mutation/Auth/ResetPassword.gql';
import UpdateAccount from '../../gql/mutation/Auth/UpdateAccount.gql';
import ChangePassword from '../../gql/mutation/Auth/ChangePassword.gql';

export default {

    forgotPassword(apolloClient, args) {

        return mutate(apolloClient, ForgotPassword, args);
    },

    resetPassword(apolloClient, args) {

        return mutate(apolloClient, ResetPassword, args);
    },

    updateAccount(apolloClient, args) {

        return mutate(apolloClient, UpdateAccount, args);
    },

    changePassword(apolloClient, args) {

        return mutate(apolloClient, ChangePassword, args);
    },
};
