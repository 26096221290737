import di from 'di'

import { GlobalPlugin, StoragePlugin } from '@wittignl/js-core'
import { OverlayPlugin, ToastPlugin, ToastType } from '@wittignl/js-ui';

import { gekOpHakenPlugin } from "@gekophaken/widgets";

export default function(app){

    // Core
    app.use(new OverlayPlugin());
    app.use(new StoragePlugin());
    app.use(GlobalPlugin, {
        $di: di
    })

    // Store
    //app.use(store);

    // Toast types
    const toastPlugin = new ToastPlugin()
        .type(ToastType.POSITIVE, 'bx bx-check-circle', ['positive'])
        .type(ToastType.NEGATIVE, 'bx bx-error-circle', ['negative'])
        .type(ToastType.WARNING, 'bx bx-error', ['warning'])
        .type(ToastType.NOTICE, 'bx bx-info-circle', ['notice']);

    app.use(toastPlugin, { i18n: di.get('i18n').global });

    // Config
    const config = di.get('config');

    // GekOpHaken Widgets
    app.use(gekOpHakenPlugin, {

        apiUrl: config.apiUrl,
        oauthAuthorizeUrl: config.oauthAuthorizeUrl,
        oauthRedirectUrl: config.oauthRedirectUrl,

        mollie: di.get('mollie'),
        i18n: di.get('i18n')
    });
}