<template>

    <teleport v-if="enabledTeleports.login" to="#goh-login">
        <goh-login></goh-login>
    </teleport>

    <teleport v-if="enabledTeleports.register" to="#goh-register">
        <goh-register></goh-register>
    </teleport>

    <teleport v-if="enabledTeleports.forgotPassword" to="#goh-forgot-password">
        <goh-forgot-password></goh-forgot-password>
    </teleport>

    <teleport v-if="enabledTeleports.resetPassword" to="#goh-reset-password">
        <goh-reset-password></goh-reset-password>
    </teleport>

    <teleport v-if="enabledTeleports.profile" to="#goh-profile">
        <goh-profile></goh-profile>
    </teleport>

    <teleport v-if="enabledTeleports.pay" to="#goh-pay">
        <goh-pay></goh-pay>
    </teleport>

    <teleport v-if="enabledTeleports.logout" to="#goh-logout">
        <goh-logout></goh-logout>
    </teleport>

    <ui-toast-container/>
    <ui-overlay-container/>

</template>

<script>

import { Vue, Options } from 'vue-class-component';


export default Options({

    name: 'App'
})(
    class App extends Vue {

        enabledTeleports = {

            login: false,
            register: false,
            forgotPassword: false,
            resetPassword: false,
            profile: false,
            pay: false,
            logout: false
        };

        created(){

            this.enabledTeleports = {

                login: document.getElementById('goh-login') != null,
                register: document.getElementById('goh-register') != null,
                forgotPassword: document.getElementById('goh-forgot-password') != null,
                resetPassword: document.getElementById('goh-reset-password') != null,
                profile: document.getElementById('goh-profile') != null,
                pay: document.getElementById('goh-pay') != null,
                logout: document.getElementById('goh-logout') != null
            };
        }
    }
);

</script>