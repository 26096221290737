export default function(input){

    //@TODO: Proper formatting library.

    if (input < 12 || input % 12 !== 0) {

        return `${input} ${ input === 1 ? 'maand' : 'maanden' }`;
    }

    return `${input / 12} jaar`;
}