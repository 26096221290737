import di from 'di'

import first from 'lodash/first'
import merge from 'lodash/merge'

import { i18n as coreMessages } from '@wittignl/js-core'
import { i18n as uiMessages } from '@wittignl/js-ui'
import appMessages from '@/js/i18n'

import { createI18n } from 'vue-i18n'
import { Settings } from 'luxon'

export default function(app){

    const config = di.get('config');

    // Auto-select language
    const supportedLanguages = config.i18n.languages;
    const defaultLanguage = config.i18n.defaultLanguage;

    let locale = navigator.language ? first(navigator.language.split('-')) : defaultLanguage;

    if(supportedLanguages.indexOf(locale) < 0){
        locale = defaultLanguage;
    }

    const messages = merge(coreMessages, uiMessages, appMessages);

    // I18n
    const i18n = createI18n({
        locale,
        fallbackLocale: defaultLanguage,
        messages,
    });

    // Luxon
    Settings.defaultLocale = locale;

    di.set('i18n', i18n);
    app.use(i18n);
}