<template>
    <div class="goh-price" :class="{ vertical, center }">
        <span
            class="price"
            :class="{
                'price-regular': priceDiscount == null,
                'price-outdated': priceDiscount != null,

                'price-primary': priceDiscount == null && primary,
                'price-black': priceDiscount == null && black,
            }"
        >
            <span v-html="priceRegularPrefix"></span>

            <span v-if="priceRegular">{{ $f.number(priceRegular, 2, 2) }}</span>
            <span v-else>Gratis</span>

            <span v-html="priceRegularSuffix"></span>
        </span>

        <span
            v-if="priceDiscount != null"
            class="price price-regular"
            :class="{
                'price-primary': primary,
                'price-black': black,
            }"
        >
            <span v-html="priceDiscountPrefix"></span>
            <span>{{ $f.number(priceDiscount, 2, 2) }}</span>
            <span v-html="priceDiscountSuffix"></span>
        </span>
    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';


export default Options({

    name: 'goh-price',
    props: {

        priceRegularPrefix: {

            type: String,
            default: '&euro; '
        },
        priceRegular: {

            type: [Number, String],
            default: null
        },
        priceRegularSuffix: {

            type: String,
            default: ''
        },

        priceDiscountPrefix: {

            type: String,
            default: '&euro; '
        },
        priceDiscount: {

            type: [Number, String],
            default: null
        },
        priceDiscountSuffix: {

            type: String,
            default: ''
        },

        vertical: {

            type: Boolean,
            default: false
        },

        center: {

            type: Boolean,
            default: false
        },

        primary: {

            type: Boolean,
            default: false
        },

        black: {

            type: Boolean,
            default: false
        }
    }
})(
    class Price extends Vue {}
);

</script>

<style lang="scss" src="../../../scss/component/ui/Price.scss"></style>