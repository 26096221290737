import PaymentMethod from "../enum/PaymentMethod";

export default function(input){

    switch(input){

        case PaymentMethod.CREDIT_CARD: return 'Creditcard';
        case PaymentMethod.IDEAL: return 'iDeal';
        case PaymentMethod.SOFORT: return 'SOFORT Banking';
        case PaymentMethod.BANCONTACT: return 'Bancontact';
        case PaymentMethod.MANUAL: return 'Handmatig';
        case PaymentMethod.MANDATE: return 'Incasso';

        default: return '';
    }
}