<template>

    <goh-widget>
        <div class="goh-profile">
            <div class="profile-menu">
                <span class="menu-title">Menu</span>
                <ui-button @click="selectedTab = 'ACCOUNT'" :active="isPageActive('ACCOUNT')" :color="isPageActive('ACCOUNT') ? 'primary' : 'neutral'" type="quiet" icon="bx bx-user">Persoonlijke gegevens</ui-button>
                <ui-button @click="selectedTab = 'CHANGE_PASSWORD'" :active="isPageActive('CHANGE_PASSWORD')" :color="isPageActive('CHANGE_PASSWORD') ? 'primary' : 'neutral'" type="quiet" icon="bx bx-key" color="neutral">Wachtwoord wijzigen</ui-button>
                <ui-button @click="selectedTab = 'SUBSCRIPTION'" :active="isPageActive('SUBSCRIPTION')" :color="isPageActive('SUBSCRIPTION') ? 'primary' : 'neutral'" type="quiet" icon="bx bx-cog" color="neutral">Mijn lidmaatschap</ui-button>
            </div>
            <div class="profile-content">
                <goh-profile-account v-if="selectedTab == 'ACCOUNT'"></goh-profile-account>
                <goh-profile-change-password v-else-if="selectedTab == 'CHANGE_PASSWORD'"></goh-profile-change-password>
                <goh-profile-subscription v-else-if="selectedTab == 'SUBSCRIPTION'"></goh-profile-subscription>
            </div>
        </div>
    </goh-widget>

</template>

<script>

import { Vue, Options } from 'vue-class-component';
import Widget from './Widget.vue';

import { Grid, Button } from '@wittignl/js-ui';

import ProfileAccount from '../ui/profile/ProfileAccount.vue';
import ProfileChangePassword from '../ui/profile/ProfileChangePassword.vue';
import ProfileSubscription from '../ui/profile/ProfileSubscription.vue';

import { ProfileTab } from '../../enum/ProfileTab';

export default Options({
    name: 'goh-profile',
    components: {
        [Widget.name]: Widget,
        [Grid.name]: Grid,
        [ProfileAccount.name]: ProfileAccount,
        [ProfileChangePassword.name]: ProfileChangePassword,
        [ProfileSubscription.name]: ProfileSubscription,
        [Button.name]: Button
    }
})(
    class Profile extends Vue {

        selectedTab = ProfileTab.ACCOUNT;

        created(){

            let urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');

            if(action === 'updateMandate'){
                this.selectedTab = ProfileTab.SUBSCRIPTION;
            }
        }

        isPageActive(page) {
            return this.selectedTab === page;
        }
    }
);

</script>

<style lang="scss" src="../../../scss/component/widget/Profile.scss"></style>