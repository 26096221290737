<template>
    <div class="goh-profile-subscription" v-spinner="loading">
        <template v-if="transactions && viewer">
            <div class="intro">
                <h2>Mijn lidmaatschap</h2>
                <p>Hieronder zie je jouw lidmaatschap bij Gek op Haken. Wil je daar iets in wijzigen? Neem dan contact met ons op.</p>
            </div>

            <section v-if="viewer?.activeSubscription?.membership">
                <h3>Actief lidmaatschap</h3>
                <ui-panel padding class="membership">
                    <div class="image"></div>
                    <div class="content">
                        <span class="title">{{ viewer.activeSubscription.membership.title }}</span>
                        <span class="subtitle">
                            Gestart op <strong>{{ $f.date(viewer.activeSubscription.startDate, 'DDD') }}</strong>
                            <template v-if="viewer.activeSubscription.endDate">
                                , eindigt op <strong>{{ $f.date(viewer.activeSubscription.endDate, 'DDD') }}</strong>
                            </template>
                        </span>
                        <span class="subtitle price">&euro; {{ $f.number(viewer.activeSubscription.membership.paymentPrice, 2, 2) }} {{ $f.paymentFrequencySuffix(viewer.activeSubscription.membership.paymentFrequency) }}</span>
                        <span class="subtitle cancel-cancelation" v-if="viewer.activeSubscription.endDate">
                            <a href="#" @click.prevent="restoreSubscription">Ik wil mijn opzegging graag annuleren</a>
                        </span>
                    </div>
                </ui-panel>
            </section>
            <section v-else-if="viewer?.latestSubscription && viewer.latestSubscription.state === 'FAILED'">
                <h3>Lidmaatschap</h3>
                <ui-panel padding class="membership inactive">
                    <div class="content">
                        <span class="title">Mislukte betaling</span>
                        <span class="subtitle">Je lidmaatschap is inactief vanwege een mislukte betaling. Neem contact met ons op.</span>
                    </div>
                </ui-panel>
            </section>
            <section v-else>
                <h3>Lidmaatschap</h3>
                <ui-panel padding class="membership inactive">
                    <div class="content">
                        <span class="title">Jouw lidmaatschap is op dit moment niet actief.</span>
                        <span class="subtitle">Jouw lidmaatschap hebben wij tijdelijk op niet-actief gezet. Waarschijnlijk heeft dit te maken met een betalingsachterstand. Check je mail of neem zelf contact met ons op via welkom@gekophaken.nl</span>
                    </div>
                </ui-panel>
            </section>

            <section>
                <h3>Laatste betalingen</h3>
                <ui-panel v-if="transactions.items.length">
                    <ui-table class="payments">
                        <tr v-for="transaction of transactions.items">
                            <td class="date">{{ $f.date(transaction.date, 'D') }}</td>
                            <td class="description">{{ transaction.description }}</td>
                            <td class="price">&euro; {{ $f.number(transaction.amount, 2, 2) }}</td>
                            <td class="state">{{ $f.transactionState(transaction.state) }}</td>
                            <td class="actions">
                                <a v-if="transaction.payUrl"
                                   :href="transaction.payUrl" target="_blank">
                                    Betalen
                                </a>
                            </td>
                        </tr>
                    </ui-table>
                </ui-panel>
                <span v-else class="section-placeholder">Je hebt nog geen transacties</span>
            </section>

            <template v-if="viewer?.activeSubscription?.membership">
                <section>
                    <h3>Betaalgegevens</h3>
                    <p class="section-description">Om jouw betaalgegevens te wijzigen maak je eenmalig maximaal € 0,10 cent aan ons over (afhankelijk van de betaalmethode) vanaf het juiste rekeningnummer.</p>

                    <!-- Todo: Knop van maken? (zie teksten Timo) -->
                    <ui-checkbox v-model="editPaymentInfo" :disabled="hasSavingSpinner">Ik wil mijn betaalgegevens wijzigen</ui-checkbox>

                    <div class="payment-method-wrapper">
                        <template v-if="editPaymentInfo">
                            <ui-panel padding style="margin-top: 1.5rem">
                                <goh-payment-method-selector ref="methodSelector" v-model="selectedPayment" :payment-methods="paymentMethods" :disabled="hasSavingSpinner"></goh-payment-method-selector>
                            </ui-panel>
                            <ui-button class="button-save" color="primary" :loading="hasSavingSpinner" :disabled="!selectedPayment" @click="updateMandate">Betalen</ui-button>
                        </template>

                        <div class="goh-error-block" v-if="pendingTransaction?.paymentError">
                            <span class="title">De betaling is mislukt.</span>
                            <p>{{ $f.paymentError(pendingTransaction.paymentError) }}</p>
                        </div>
                    </div>

                </section>

                <template v-if="!viewer.activeSubscription.endDate">
                    <section v-if="activeSubscriptionMonthly && viewer.activeSubscription.minCancelDate">
                        <h3>Lidmaatschap opzeggen</h3>
                        <p class="section-description">
                            We vinden het jammer als je jouw lidmaatschap opzegt. Weet je wat je <a href="https://gekophaken.nl/ledenvoordelen/" target="_blank">allemaal mist</a>? In de toekomst zullen we de prijzen van onze lidmaatschappen weer verhogen. Je gaat dan dus meer betalen als je opnieuw lid wordt. Zolang je lid blijft, wijzigt de prijs van jouw lidmaatschap niet. Toch opzeggen? Klik dan <a href="#" @click.prevent="cancelSubscription">hier</a>.
                        </p>
                    </section>
                    <section v-else>
                        <h3>Lidmaatschap opzeggen</h3>
                        <p class="section-description">
                            Jouw lidmaatschap kan helaas niet automatisch worden opgezegd. We vinden het jammer als je jouw lidmaatschap opzegt. Weet je wat je <a href="https://gekophaken.nl/ledenvoordelen/" target="_blank">allemaal mist</a>? Toch opzeggen? Neem dan contact met ons op.
                        </p>
                    </section>
                </template>

            </template>
            <template v-else-if="viewer.latestSubscription">
                <section>
                    <h3>Lidmaatschap opzeggen</h3>
                    <p class="section-description">
                        Jouw lidmaatschap kan niet automatisch worden opgezegd omdat deze (tijdelijk) niet actief is.
                    </p>
                </section>
            </template>

        </template>
    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';
import { Grid, Panel, Checkbox, Table, TableHeader, TableHeaderColumn, Button } from '@wittignl/js-ui';
import { createQuery, getGraphQLError } from '@wittignl/js-core';
import { DateTime } from 'luxon';

import PaymentMethodSelector from '../PaymentMethodSelector.vue';

import AllTransactionsQuery from '../../../../gql/query/ProfileSubscription/AllTransactions.gql';
import ViewerQuery from '../../../../gql/query/ProfileSubscription/Viewer.gql';
import FindPaymentMethods from '../../../../gql/query/ProfileSubscription/FindPaymentMethods.gql';
import FindTransactionByToken from '../../../../gql/query/Register/FindTransactionByToken.gql';
import SubscriptionMutations from '../../../mutation/SubscriptionMutations';

import TransactionState from 'common/js/enum/TransactionState';
import PaymentMethod from 'common/js/enum/PaymentMethod';

export default Options({
    name: 'goh-profile-subscription',
    components: {
        [Button.name]: Button,
        [Grid.name]: Grid,
        [Panel.name]: Panel,
        [Table.name]: Table,
        [Checkbox.name]: Checkbox,
        [TableHeader.name]: TableHeader,
        [TableHeaderColumn.name]: TableHeaderColumn,
        [PaymentMethodSelector.name]: PaymentMethodSelector
    },
    apollo: {

        transactions: createQuery(AllTransactionsQuery, {

            variables: {
                limit: 5,
                offset: 0,
                sort: {
                    field: 'date',
                    direction: 'DESC'
                }
            }
        }),

        viewer: createQuery(ViewerQuery),
        paymentMethods: createQuery(FindPaymentMethods),

        pendingTransaction: createQuery(FindTransactionByToken, {

            loadingKey: 'loadingTransaction',

            skip(){
                return !this.token
            },

            variables(){

                return {
                    token: this.token
                }
            },

            response(transaction){

                this.selectedPayment = {
                    method: transaction.paymentMethod,
                    issuer: transaction.paymentIssuer
                };

                this.editPaymentInfo = true;

                switch(transaction.state){

                    case TransactionState.COMPLETED:
                        this.$toast.success('De betalingswijze is succesvol gewijzigd.');
                        this.editPaymentInfo = false;
                        break;

                    case TransactionState.PENDING:
                        setTimeout(() => this.$apollo.queries.pendingTransaction.refetch(), 1000);
                        break;
                }
            }
        })
    }
})(
    class ProfileSubscription extends Vue {

        loading = 0;
        loadingTransaction = 0;

        updatingMandate = false;

        editPaymentInfo = false;
        selectedPayment = null;

        token = null;

        created(){

            let urlParams = new URLSearchParams(window.location.search);
            this.token = urlParams.get('token');
        }

        get activeSubscriptionMonthly(){

            return this.viewer?.activeSubscription?.membership?.paymentFrequency === 1;
        }

        async updateMandate(){

            if(!this.$refs.methodSelector.validate()){
                this.$toast.validation();
                return;
            }

            this.updatingMandate = true;

            try {

                const cardToken = await this.$mollie.createToken();

                const updateResponseResponse = await SubscriptionMutations.updateSubscriptionMandate(this.$apollo, {
                    payment: {
                        ...(this.selectedPayment?.method === PaymentMethod.CREDIT_CARD ? { cardToken: cardToken.token }  : {}),
                        ...this.selectedPayment
                    }
                });

                window.location.replace(updateResponseResponse.data.updateSubscriptionMandate);
            }
            catch(e){

                this.logger.error(e);
                const error = getGraphQLError(e);

                switch(error?.type){

                    default:
                        this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
                        break;
                }
            }

            this.updatingMandate = true;
        }

        async cancelSubscription(){

            const endDate = DateTime.fromISO(this.viewer.activeSubscription.minCancelDate);

            const { result, overlay } = await this.$alert.confirm('Weet je zeker dat je je lidmaatschap wilt beëindigen?', true, {

                    subtitle: DateTime.now() >= endDate
                        ? 'Je lidmaatschap wordt per direct beëindigd'
                        : `Je lidmaatschap word beëindigd op ${endDate.toFormat('DDD')}`
                }
            );

            if(!result){
                return;
            }

            try {

                await SubscriptionMutations.cancelUserSubscription(this.$apollo);
                this.$toast.success('Je lidmaatschap wordt geannuleerd');

                this.$apollo.queries.viewer.refetch();
            }
            catch(e){

                this.logger.error(e);
                this.$toast.error('Je lidmaatschap kon niet worden beëindigd');
            }

            this.$overlay.close(overlay);
        }

        async restoreSubscription(){

            const { result, overlay } = await this.$alert.confirm('Weet je zeker dat je de opzegging van je lidmaatschap wilt annuleren?', true);
            if(!result){
                return;
            }

            try {

                await SubscriptionMutations.restoreUserSubscription(this.$apollo);
                this.$toast.success('De opzegging is geannuleerd');

                this.$apollo.queries.viewer.refetch();
            }
            catch(e){

                this.logger.error(e);
                this.$toast.error('De opzegging kan niet worden geannuleerd');
            }

            this.$overlay.close(overlay);
        }

        get hasSavingSpinner(){

            return this.updatingMandate || this.pendingTransaction?.state === TransactionState.PENDING;
        }
    }
);

</script>

<style scoped lang="scss" src="../../../../scss/component/ui/profile/ProfileSubscription.scss"></style>