//import { invalidateQuery } from "@wittignl/js-core";
import mutate from "../util/mutate";

import Register from "../../gql/mutation/Registration/Register.gql";
import PreRegister from "../../gql/mutation/PreRegistration/PreRegister.gql";
import UpdatePreRegistration from "../../gql/mutation/PreRegistration/UpdatePreRegistration.gql";


export default {

    preRegister(apolloClient, args) {

        return mutate(apolloClient, PreRegister, args);
    },

    updatePreRegistration(apolloClient, args) {

        return mutate(apolloClient, UpdatePreRegistration, args);
    },

    register(apolloClient, args) {

        return mutate(apolloClient, Register, args);
    }
};
