import di from 'di'

import defaultConfig from '@/js/config/config.default';
import developmentConfig from '@/js/config/config.development';
import stagingConfig from '@/js/config/config.staging';
import productionConfig from '@/js/config/config.production';

export default function(){

    const ENV = import.meta.env.MODE;

    let config = defaultConfig;
    let envConfig = {};

    if(ENV === 'development'){
        envConfig = developmentConfig;
    }
    else if(ENV === 'staging'){
        envConfig = stagingConfig;
    }
    else if(ENV === 'production'){
        envConfig = productionConfig;
    }

    Object.assign(config, envConfig);
    di.set('config', config);
}