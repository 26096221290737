export default function(input){

    switch(input){

        //@TODO: ENUM
        case 1: return 'Vanaf volgende maand';
        case 6: return 'Vanaf volgend halfjaar';
        case 12: return 'Vanaf volgend jaar';

        default: return `Vanaf de volgende ${input} maanden`;
    }
}