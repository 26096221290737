export default function(input){

    switch(input){

        //@TODO: ENUM
        case 1: return 'maandelijkse betaling';
        case 6: return 'halfjaarlijkse betaling';
        case 12: return 'jaarlijkse betaling';

        default: return `${input}-maandelijkse betaling`;
    }
}