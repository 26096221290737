<template>
    <goh-widget>
        <div class="goh-logout">
            <div v-spinner="true"></div>
        </div>
    </goh-widget>
</template>

<script>

import { Options, Vue } from 'vue-class-component';
import Widget from './Widget.vue';


export default Options({
    name: 'goh-logout',
    components: {
        [Widget.name]: Widget,
    }
})(
    class Logout extends Vue {

        created() {

            this.$auth.logout();
            window.location.href = "/";
        }
    }
);

</script>
<style src="../../../scss/component/widget/Authentication.scss" lang="scss"></style>