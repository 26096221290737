import mutate from "../util/mutate";

import UpdateSubscriptionMandate from "../../gql/mutation/Subscription/UpdateSubscriptionMandate.gql";
import CancelUserSubscription from "../../gql/mutation/Subscription/CancelUserSubscription.gql";
import RestoreUserSubscription from "../../gql/mutation/Subscription/RestoreUserSubscription.gql";
import PaySubscription from "../../gql/mutation/Subscription/PaySubscription.gql";

export default {

    updateSubscriptionMandate(apolloClient, args) {

        return mutate(apolloClient, UpdateSubscriptionMandate, args);
    },

    paySubscription(apolloClient, args) {

        return mutate(apolloClient, PaySubscription, args);
    },

    cancelUserSubscription(apolloClient) {

        return mutate(apolloClient, CancelUserSubscription);
    },

    restoreUserSubscription(apolloClient) {

        return mutate(apolloClient, RestoreUserSubscription);
    },
};
