export enum ApiErrorType {

    // Application
    NOT_FOUND = 'NotFoundError',

    DUPLICATE = 'DuplicateError',
    USER_DUPLICATE = 'UserDuplicateError',
    PASSWORD_WEAK = 'PasswordWeakError',
    PASSWORD_INVALID = 'PasswordInvalidError',
    EMAIL_VALIDATION = 'EmailValidationError',
    SUBSCRIPTION_ACTIVE_ERROR = 'SubscriptionActiveError',
    USER_INACTIVE_ERROR = 'UserInactiveError'
}

export default ApiErrorType;