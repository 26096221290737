<template>

    <div class="goh-mollie-component">
        <span class="title">{{ title }}</span>
        <div ref="holder" />
        <div v-if="error && touched" class="error-message">{{ error }}</div>
    </div>

</template>

<script>

import { Vue, Options } from "vue-class-component";

export default Options({
    name: 'goh-mollie-component',
    props: ['type', 'title']
})(
    class MollieComponent extends Vue
    {
        error = null;
        touched = false;

        created(){

            this.component = this.$mollie.getComponent(this.type);
        }

        mounted(){

            this.component.mount(this.$refs.holder);
            this.component.addEventListener('change', e => this.onChange(e));
        }

        beforeDestroy(){

            this.component.unmount();
        }

        onChange(e){

            this.error = e.error;
            this.touched = e.touched;
        }

        isValid(){

            return !this.error && this.touched;
        }
    }
)

</script>
<style lang="scss" src="../../../scss/component/ui/MollieComponent.scss"></style>