import PaymentError from "../enum/PaymentError";

export default function(input){

    switch(input){

        case PaymentError.PAYMENT_CANCELED: return 'De betaling is geannuleerd. Probeer het opnieuw, eventueel met een andere betaalmethode.';
        case PaymentError.PAYMENT_EXPIRED: return 'De betaling is verlopen. Probeer het opnieuw, eventueel met een andere betaalmethode.';
        case PaymentError.AUTHENTICATION_FAILED: return '';
        case PaymentError.CARD_EXPIRED: return 'Deze credit card is verlopen. Probeer het opnieuw met een andere betaalmethode.';
        case PaymentError.INACTIVE_CARD: return 'Deze credit card is inactief. Probeer het opnieuw met een andere betaalmethode.';
        case PaymentError.INSUFFICIENT_FUNDS: return 'Saldo ontoereikend. Probeer het opnieuw met een andere betaalmethode.';
        case PaymentError.INVALID_CARD_HOLDER_NAME: return 'De kaarthouder van deze credit card klopt niet. Corrigeer de gegevens en probeer het opnieuw.';
        case PaymentError.INVALID_CARD_NUMBER: return 'Het kaartnummer van deze credit card is ongeldig. Corrigeer de gegevens en probeer het opnieuw.';
        case PaymentError.INVALID_CARD_TYPE: return 'Het type van deze credit card is ongeldig. Corrigeer de gegevens en probeer het opnieuw.';
        case PaymentError.INVALID_CVC: return 'De beveiligingscode van deze credit card is ongeldig. Corrigeer de gegevens en probeer het opnieuw.';
        case PaymentError.POSSIBLE_FRAUD: return 'Er is mogelijk fraude gedetecteerd. Neem contact op met uw bank en probeer het opnieuw.';
        case PaymentError.ISSUER_REFUSED: return 'De betaling is geweigerd. Probeer het opnieuw, eventueel met een andere betaalmethode.';
        case PaymentError.MOLLIE_CHARGE_FAILED: return 'Kon betaling niet aanmaken bij mollie';

        default:
        case PaymentError.UNKNOWN: return 'De betaling mislukt. Probeer het opnieuw, eventueel met een andere betaalmethode.';
    }
}