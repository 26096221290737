import axios from 'axios'
import { createQuery } from '@wittignl/js-core'

import ViewerQuery from '../../gql/query/Viewer.gql'

export default class EmailAccountType {

    static NAME = 'email';

    apollo = null;
    http = null;
    apiUrl = null;

    constructor(apollo, http, apiUrl){

        this.apollo = apollo;
        this.http = http;
        this.apiUrl = apiUrl;
    }

    async login(credentials){

        let data;

        if (credentials.token != null) {
            data = credentials.token;
        }
        else {

            const response = await this.http.post('auth/access_token', {
                username: credentials.username,
                password: credentials.password
            });

            data = response?.data;
            if (!data) {
                throw 'Invalid login response';
            }
        }

        const initResult = await this.apollo.query(createQuery(ViewerQuery, {

            fetchPolicy: 'network-only',

            context: {
                headers: {
                    Authorization: `Bearer ${data.token}`
                }
            }
        }));

        const user = initResult?.data?.viewer;
        if(!user){
            throw 'Invalid init response';
        }

        return {
            identity: user,
            startDate: new Date().getTime(),
            expires: data.expires,
            token: data.token,
            refreshToken: data.refreshToken
        }
    }

    async refreshToken(session){

        if(!session.refreshToken){
            throw 'No refresh token available'
        }

        const { data } = await axios.post(this.apiUrl + '/auth/refresh_token', {
            refreshToken: session.refreshToken
        });

        if(!data){
            throw 'Invalid refresh token response';
        }

        return {
            ...session,
            startDate: new Date().getTime(),
            expires: data.expires,
            token: data.token,
            refreshToken: data.refreshToken
        }
    }
}
