import { decodeJwt } from 'jose';

export default async function validateSession(auth): Promise<void> {

    // If there is no session to check, skip.
    if(!auth.session){
        return;
    }

    // Read payload data
    const { identityMembers, identityCafe, exp } = decodeJwt(auth.session.token); //@TODO: Selector for which website... (required when widgets are placed on the HaakCafe)

    // If token is expired, refresh the token.
    if(exp != null && (Date.now() >= (exp * 1000))){

        await auth.refreshToken();
    }

    // Retrieve the platform identity
    const identityPlatform: string = document.currentScript?.getAttribute('data-identity');

    // Check if the platform identity matches the client side identity.
    if(identityMembers != null && (identityPlatform == null || identityPlatform == "")){

        window.location.href = '?option=oauthredirect&app_name=GekOpHaken';
    }
    else if(identityMembers !== identityPlatform){

        // Terminate invalid sessions
        await auth.logout();
    }
}