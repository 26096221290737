import di from 'di'

import { defineRule, configure } from 'vee-validate';
import { required, confirmed, max } from '@vee-validate/rules';
import { createRegexValidator } from '@wittignl/js-core';
import { passwordRegex, emailRegex } from 'common/js/regex';

export default function(){

    // Add rules
    defineRule('required', required);
    defineRule('email', createRegexValidator(emailRegex));
    defineRule('confirmed', confirmed);
    defineRule('password', createRegexValidator(passwordRegex));
    defineRule('max', max);

    // Configure
    configure({
        generateMessage: context => {

            const i18n = di.get('i18n').global;

            let paramIndex = 1;
            let params = {};

            for(let param of context.rule?.params || []){

                params[`param${paramIndex}`] = param;
                paramIndex++;
            }

            return i18n.t(`validation.${context.rule.name}`, { field: context.field, ...params });
        }
    });
}