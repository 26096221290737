<template>
    <div class="goh-payment-method-selector">

        <div class="goh-payment-method" v-for="option of paymentMethodsOptions" :key="option.value">

            <ui-radio
                class="goh-payment-method-radio"
                name="payment_method" :radio-value="option.value"
                v-model="selectedPaymentMethod" :disabled="disabled">

                <div class="goh-payment-method-radio-inner">
                    <span>{{ option.label }}</span>
                    <img :src="option.image">
                </div>

            </ui-radio>

            <div class="goh-payment-method-issuer-selection" v-if="selectedPaymentMethod === option.value && hasPaymentDetails(option)">
                <div v-if="selectedPaymentMethod === 'CREDIT_CARD'">
                    <ui-grid class="credit-card-form-grid">
                        <div class="col-12">
                            <goh-mollie-component ref="cardHolder" type="cardHolder" title="Kaarthouder"></goh-mollie-component>
                        </div>
                        <div class="col-12">
                            <goh-mollie-component ref="cardNumber" type="cardNumber" title="Kaartnummer"></goh-mollie-component>
                        </div>
                        <div class="col-6">
                            <goh-mollie-component ref="expiryDate" type="expiryDate" title="Vervaldatum"></goh-mollie-component>
                        </div>
                        <div class="col-6">
                            <goh-mollie-component ref="verificationCode" type="verificationCode" title="Verificatiecode"></goh-mollie-component>
                        </div>
                    </ui-grid>
                </div>

                <goh-dropdown
                    v-else-if="hasIssuerOptions(option.value)"
                    v-model="selectedPaymentIssuer" searchable :disabled="disabled"
                    placeholder="Selecteer je bank..."
                    :options="paymentIssuerOptions[option.value]">
                </goh-dropdown>

            </div>

        </div>

        <div class="goh-payment-trustbanner">
            <div class="top">
                <i class="bx bxs-lock-alt"></i>
                <span>Veilig betalen samen met <img src="../../../img/logo_mollie.png" /></span>
            </div>
            <div class="logos" v-if="false">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';
import { innerValueMixin } from '@wittignl/js-core';
import { Radio, Grid } from '@wittignl/js-ui';

import MollieComponent from './MollieComponent.vue';
import PaymentMethod from "common/js/enum/PaymentMethod";
import Dropdown from './Dropdown.vue';

const paymentMethodSortOrder = [

    "IDEAL",
    "BANCONTACT",
    "SOFORT",
    "CREDIT_CARD"
];


export default Options({

    name: 'goh-payment-method-selector',
    mixins: [innerValueMixin],
    props: {

        disabled: Boolean,

        modelValue: Object,

        paymentMethods: {
            type: Array,
            default: () => []
        }
    },
    components: {

        [Radio.name]: Radio,
        [Dropdown.name]: Dropdown,
        [Grid.name]: Grid,
        [MollieComponent.name]: MollieComponent
    }
})(
    class PaymentMethodSelector extends Vue {

        selectedPaymentMethod = null;
        selectedPaymentIssuer = null;

        created(){

            this.$watch('modelValue', (newValue) => {

                if(newValue){
                    this.selectedPaymentMethod = newValue?.method;
                    this.selectedPaymentIssuer = newValue?.issuer;
                }
            });

            this.$watch('selectedPaymentIssuer', () => this.updateInnerValue());

            this.$watch('selectedPaymentMethod', (method) => {

                this.selectedPaymentIssuer = null;
                this.updateInnerValue();
            });

            this.selectedPaymentMethod = this.modelValue?.method || null;

            this.$nextTick(() => {

                this.selectedPaymentIssuer = this.modelValue?.issuer || null;
            })
        }

        hasIssuerOptions(method){

            return this.paymentIssuerOptions[method] != null && this.paymentIssuerOptions[method].length > 0
        }

        hasPaymentDetails(option){

            return option.value === PaymentMethod.CREDIT_CARD || this.hasIssuerOptions(option.value);
        }

        updateInnerValue(){

            const method = this.selectedPaymentMethod;
            if(method){

                if(this.hasIssuerOptions(method) && this.selectedPaymentIssuer){

                    this.innerValue = {
                        method: this.selectedPaymentMethod,
                        issuer: this.selectedPaymentIssuer
                    }

                    return;
                }
                else if(!this.hasIssuerOptions(method)){

                    this.innerValue = {
                        method: this.selectedPaymentMethod
                    }

                    return;
                }
            }

            this.innerValue = null;
        }

        validate(){

            if(this.selectedPaymentMethod === PaymentMethod.CREDIT_CARD){

                const fields = [this.$refs.cardHolder, this.$refs.cardNumber, this.$refs.expiryDate, this.$refs.verificationCode];

                for(let field of fields){

                    if(!field[0].isValid()){
                        return false;
                    }
                }
            }

            return true;
        }

        get paymentMethodsOptions(){

            if ((this.paymentMethods?.length || 0) <= 0) {
                return [];
            }

            return (

                this.paymentMethods.map(pm => ({

                    image: pm.imageUrl,

                    label: this.$f.paymentMethod(pm.method),
                    value: pm.method

                })).sort(
                    (a, b) => paymentMethodSortOrder.indexOf(a.value) - paymentMethodSortOrder.indexOf(b.value)
                )
            )
        }

        get paymentIssuerOptions(){

            const paymentIssuerOptions = {};

            for(const paymentMethod of this.paymentMethods || []){

                if(paymentMethod.issuers == null || paymentMethod.issuers.length <= 0){
                    continue;
                }

                paymentIssuerOptions[paymentMethod.method] = paymentMethod.issuers.map((i) => ({

                    image: i.imageUrl,

                    label: i.title,
                    value: i.id
                }));
            }

            return paymentIssuerOptions;
        }
    }
);

</script>

<style lang="scss" src="../../../scss/component/ui/PaymentMethodSelector.scss"></style>