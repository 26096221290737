<template>
    <div class="goh-legal">

        <h2>Over ons privacybeleid</h2>
        <p>Gek op Haken geeft veel om jouw privacy. Wij verwerken daarom uitsluitend gegevens die wij nodig hebben voor (het verbeteren van) onze dienstverlening en gaan zorgvuldig om met de informatie die wij over jou en jouw gebruik van onze diensten hebben verzameld. Wij stellen jouw gegevens nooit voor commerciële doelstellingen ter beschikking aan derden.</p>
        <p>Dit privacybeleid is van toepassing op het gebruik van de websites en de daarop ontsloten dienstverlening van Gek op Haken. De ingangsdatum voor de geldigheid van deze voorwaarden is 15 januari 2020, met het publiceren van een nieuwe versie vervalt de geldigheid van alle voorgaande versies. Dit privacybeleid beschrijft welke gegevens over jou door ons worden verzameld, waar deze gegevens voor worden gebruikt en met wie en onder welke voorwaarden deze gegevens eventueel met derden kunnen worden gedeeld. Ook leggen wij aan je uit op welke wijze wij jouw gegevens opslaan en hoe wij jouw gegevens tegen misbruik beschermen en welke rechten je hebt met betrekking tot de door jou aan ons verstrekte persoonsgegevens.</p>
        <p>Als je vragen hebt over ons privacybeleid kunt je contact opnemen met onze contactpersoon voor privacyzaken, je vindt de contactgegevens aan het einde van ons privacybeleid.</p>

        <h2>Over de gegevensverwerking</h2>
        <p>Hieronder kan je lezen op welke wijze wij jouw gegevens verwerken, waar wij deze (laten) opslaan, welke beveiligingstechnieken wij gebruiken en voor wie de gegevens inzichtelijk zijn.</p>

        <h3>Webwinkelsoftware en webhosting</h3>
        <p>Onze webwinkel is ontwikkeld met software van WooCommerce, wij hebben voor onze webhosting gekozen voor Kinsta. Persoonsgegevens die je ten behoeve van onze dienstverlening aan ons beschikbaar stelt, worden met deze partij gedeeld. Kinsta heeft toegang tot jouw gegevens om ons (technische) ondersteuning te bieden, zij zullen jouw gegevens nooit gebruiken voor een ander doel.</p>
        <p>Kinsta is op basis van de overeenkomst die wij met hen hebben gesloten verplicht om passende beveiligingsmaatregelen te nemen. Deze beveiligingsmaatregelen bestaan uit de toepassing van SSL-encryptie en een sterk wachtwoordbeleid. Er worden regelmatig back-ups gemaakt om verlies van data te voorkomen.</p>

        <h3>E-mail en mailinglijsten</h3>
        <h4>ActiveCampaign</h4>
        <p>Wij versturen onze e-mail nieuwsbrieven en andere marketing emails met ActiveCampaign. ActiveCampaign zal jouw naam en e-mailadres nooit voor eigen doeleinden gebruiken. Onderaan elke e-mail die geautomatiseerd via onze website is verzonden zie je de ‘unsubscribe’ link. Je ontvangt onze nieuwsbrief dan niet meer. Jouw persoonsgegevens worden door ActiveCampaign beveiligd opgeslagen. ActiveCampaign maakt gebruik van cookies en andere internet technologieën die inzichtelijk maken of e-mails worden geopend en gelezen. ActiveCampaign behoudt zich het recht voor om jouw gegevens te gebruiken voor het verder verbeteren van de dienstverlening en in het kader daarvan informatie met derden te delen.</p>

        <h4>Gmail</h4>
        <p>Wij maken voor ons reguliere zakelijke e-mailverkeer gebruik van de diensten van Google Workspace. Deze partij heeft passende technische en organisatorische maatregelen getroffen om misbruik, verlies en corruptie van jouw en onze gegevens zoveel mogelijk te voorkomen. Google Workspace heeft geen toegang tot ons postvak en wij behandelen al ons e-mailverkeer vertrouwelijk.</p>

        <h2>Payment processors</h2>
        <h3>Mollie</h3>
        <p>Voor het afhandelen van een (deel van) de betalingen in onze webwinkel maken wij gebruik van het platform van Mollie. Mollie verwerkt jouw naam, adres en woonplaatsgegevens en jouw betaalgegevens zoals bankrekening- of creditcardnummer. Mollie heeft passende technische en organisatorische maatregelen genomen om jouw persoonsgegevens te beschermen. Mollie behoudt zich het recht voor jouw gegevens te gebruiken om de dienstverlening verder te verbeteren en in het kader daarvan (geanonimiseerde) gegevens met derden te delen. Alle hierboven genoemde waarborgen met betrekking tot de bescherming van jouw persoonsgegevens zijn eveneens van toepassing op de onderdelen van Mollie’s dienstverlening waarvoor zij derden inschakelen. Mollie bewaart jouw gegevens niet langer dan op grond van de wettelijke termijnen is toegestaan.</p>

        <h2>Doel van de gegevensverwerking</h2>
        <h3>Algemeen doel van de verwerking</h3>
        <p>Wij gebruiken jouw gegevens uitsluitend ten behoeve van onze dienstverlening. Dat wil zeggen dat het doel van de verwerking altijd direct verband houdt met de opdracht die je verstrekt. Wij gebruiken jouw gegevens niet voor (gerichte) marketing. Als je gegevens met ons deelt en wij gebruiken deze gegevens om – anders dan op jouw verzoek – op een later moment contact met je op te nemen, vragen wij je hiervoor expliciet toestemming. Jouw gegevens worden niet met derden gedeeld, anders dan om aan boekhoudkundige en overige administratieve verplichtingen te voldoen. Deze derden zijn allemaal tot geheimhouding gehouden op grond van de overeenkomst tussen hen en ons of een eed of wettelijke verplichting.</p>

        <h3>Automatisch verzamelde gegevens</h3>
        <p>Gegevens die automatisch worden verzameld door onze website worden verwerkt met het doel onze dienstverlening verder te verbeteren. Deze gegevens (bijvoorbeeld jouw IP-adres, webbrowser en besturingssysteem) zijn geen persoonsgegevens.</p>

        <h3>Medewerking aan fiscaal en strafrechtelijk onderzoek</h3>
        <p>In voorkomende gevallen kan Gek op Haken op grond van een wettelijke verplichting worden gehouden tot het delen van jouw gegevens in verband met fiscaal of strafrechtelijk onderzoek van overheidswege. In een dergelijk geval zijn wij gedwongen jouw gegevens te delen, maar wij zullen ons binnen de mogelijkheden die de wet ons biedt daartegen verzetten.</p>

        <h3>Bewaartermijnen</h3>
        <p>Wij bewaren jouw gegevens zolang je klant van ons bent. Dit betekent dat wij jouw klantprofiel bewaren totdat je aangeeft dat je niet langer van onze diensten gebruik wenst te maken. Als je dit bij ons aangeeft zullen wij dit tevens opvatten als een vergeetverzoek. Op grond van toepasselijke administratieve verplichtingen dienen wij facturen met jouw (persoons)gegevens te bewaren, deze gegevens zullen wij dus voor zolang de toepasselijke termijn loopt bewaren. Medewerkers hebben echter geen toegang meer tot jouw klantprofiel en documenten die wij naar aanleiding van jouw opdracht hebben vervaardigd.</p>

        <h3>Jouw rechten</h3>
        <p>Op grond van de geldende Nederlandse en Europese wetgeving heb je als betrokkene bepaalde rechten met betrekking tot de persoonsgegevens die door of namens ons worden verwerkt. Wij leggen je hieronder uit welke rechten dit zijn en hoe je je op deze rechten kunt beroepen.</p>
        <p>In beginsel sturen wij om misbruik te voorkomen afschriften en kopieën van jouw gegevens enkel naar jouw bij ons reeds bekende e-mailadres. In het geval dat je de gegevens op een ander e-mailadres of bijvoorbeeld per post wenst te ontvangen, zullen wij je vragen je te legitimeren. Wij houden een administratie bij van afgehandelde verzoeken, in het geval van een vergeetverzoek administreren wij geanonimiseerde gegevens. Alle afschriften en kopieën van gegevens ontvang je in de gegevensindeling die wij binnen onze systemen hanteren.</p>
        <p>Je hebt te allen tijde het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens als je vermoedt dat wij jouw persoonsgegevens op een verkeerde manier gebruiken.</p>

        <h3>Inzagerecht</h3>
        <p>Je hebt altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op jouw persoon of daartoe herleidbaar zijn, in te zien. Je kunt een verzoek met die strekking doen aan onze contactpersoon voor privacyzaken. Je ontvangt dan binnen 30 dagen een reactie op jouw verzoek. Als jouw verzoek wordt ingewilligd sturen wij je op het bij ons bekende e-mailadres een kopie van alle gegevens met een overzicht van de verwerkers die deze gegevens onder zich hebben, onder vermelding van de categorie waaronder wij deze gegevens hebben opgeslagen.</p>

        <h3>Rectificatierecht</h3>
        <p>Je hebt altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op jouw persoon of daartoe herleidbaar zijn, te laten aanpassen. Je kunt een verzoek met die strekking doen aan onze contactpersoon voor privacyzaken. Je ontvangt dan binnen 30 dagen een reactie op jouw verzoek. Als jouw verzoek wordt ingewilligd sturen wij je op het bij ons bekende e-mailadres een bevestiging dat de gegevens zijn aangepast.</p>

        <h3>Recht op beperking van de verwerking</h3>
        <p>Je hebt altijd het recht om de gegevens die wij (laten) verwerken die betrekking hebben op jouw persoon of daartoe herleidbaar zijn, te beperken. Je kunt een verzoek met die strekking doen aan onze contactpersoon voor privacyzaken. Je ontvangt dan binnen 30 dagen een reactie op jouw verzoek. Als jouw verzoek wordt ingewilligd sturen wij je op het bij ons bekende e-mailadres een bevestiging dat de gegevens tot je de beperking opheft niet langer worden verwerkt.</p>

        <h3>Recht op overdraagbaarheid</h3>
        <p>Je hebt altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op jouw persoon of daartoe herleidbaar zijn, door een andere partij te laten uitvoeren. Je kunt een verzoek met die strekking doen aan onze contactpersoon voor privacyzaken. Je ontvangt dan binnen 30 dagen een reactie op jouw verzoek. Als jouw verzoek wordt ingewilligd sturen wij je op het bij ons bekende e-mailadres afschriften of kopieën van alle gegevens over je die wij hebben verwerkt of in opdracht van ons door andere verwerkers of derden zijn verwerkt. Naar alle waarschijnlijkheid kunnen wij in een dergelijk geval de dienstverlening niet langer voortzetten, omdat de veilige koppeling van databestanden dan niet langer kan worden gegarandeerd.</p>

        <h3>Recht van bezwaar en overige rechten</h3>
        <p>Je hebt in voorkomende gevallen het recht bezwaar te maken tegen de verwerking van jouw persoonsgegevens door of in opdracht van Gek op Haken. Als je bezwaar maakt zullen wij onmiddellijk de gegevensverwerking staken in afwachting van de afhandeling van jouw bezwaar. Is jouw bezwaar gegrond dat zullen wij afschriften en/of kopieën van gegevens die wij (laten) verwerken aan je ter beschikking stellen en daarna de verwerking blijvend staken.</p>
        <p>Je hebt bovendien het recht om niet aan geautomatiseerde individuele besluitvorming of profiling te worden onderworpen. Wij verwerken jouw gegevens niet op zodanige wijze dat dit recht van toepassing is. Ben je van mening dat dit wel zo is, neem dan contact op met onze contactpersoon voor privacyzaken.</p>

        <h2>Cookies</h2>
        <h3>Google Analytics</h3>
        <p>Via onze website worden cookies geplaatst van het Amerikaanse bedrijf Google, als deel van de “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken. Deze verwerker is mogelijk verplicht op grond van geldende wet- en regelgeving inzage te geven in deze gegevens. Wij verzamelen informatie over jouw surfgedrag en delen deze gegevens met Google. Google kan deze informatie in samenhang met andere datasets interpreteren en op die manier jouw bewegingen op het internet volgen. Google gebruikt deze informatie voor het aanbieden van onder andere gerichte advertenties (Adwords) en overige Google- diensten en producten.</p>

        <h3>Cookies van derde partijen</h3>
        <p>In het geval dat softwareoplossingen van derde partijen gebruik maken van cookies is dit vermeld in deze privacyverklaring.</p>

        <h3>Wijzigingen in het privacybeleid</h3>
        <p>Wij behouden te allen tijde het recht ons privacybeleid te wijzigen. Op deze pagina vind je echter altijd de meest recente versie. Als het nieuwe privacybeleid gevolgen heeft voor de wijze waarop wij reeds verzamelde gegevens met betrekking tot jou verwerken, dan brengen wij je daarvan per e-mail op de hoogte.</p>
        <p>
            Contactgegevens Gek op Haken:<br/>
            In d’Hoef 90<br/>
            4631MJ Hoogerheide Nederland<br/>
            T 06-16970217<br/>
            E welkom@gekophaken.nl<br/>
        </p>
        <p>Contactpersoon voor privacyzaken: Karin Roosendaal</p>
        <p>Geactualiseerd: 7 december 2021</p>
    </div>
</template>

<script>

import { Vue, Options } from 'vue-class-component';


export default Options({

    name: 'goh-privacy-policy',

})(
    class PrivacyPolicy extends Vue {}
);

</script>

<style lang="scss" src="../../../scss/component/ui/Legal.scss"></style>