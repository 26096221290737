import TransactionState from "../enum/TransactionState";

export default function(input){

    switch(input){

        case TransactionState.PENDING: return 'In behandeling';
        case TransactionState.COMPLETED: return 'Voldaan';
        case TransactionState.FAILED: return 'Mislukt';
        case TransactionState.REFUNDED: return 'Terugbetaald';
        case TransactionState.PROCESSING: return 'Voldaan';
        case TransactionState.ON_HOLD: return 'In behandeling';
        case TransactionState.CANCELLED: return 'Geannuleerd';
        case TransactionState.CHARGED_BACK: return 'Teruggestort';
        case TransactionState.EXPIRED: return 'Verlopen';

        default: return '';
    }
}