<template>

    <goh-widget>
        <div class="goh-register" v-spinner="loading">
            <ui-form ref="registerForm" class="register-form" v-if="membership != null" @submit="submitRegistration">

                <section class="register-section" v-if="membership.giftMode !== 'NONE'">
                    <div class="register-section-intro">

                        <template v-if="membership.giftMode === 'SINGLE'">
                            <span class="register-section-title">Jouw welkomstcadeau 🎉</span>
                            <span class="register-section-subtitle">Je krijgt een welkomstcadeau bij jouw lidmaatschap!</span>
                        </template>
                        <template v-else-if="membership.giftMode === 'SINGLE_CHOICE'">
                            <span class="register-section-title">Kies jouw welkomstcadeau 🎉</span>
                            <span class="register-section-subtitle">Je mag een welkomstcadeaus kiezen bij jouw lidmaatschap! Maak hieronder je keuze.</span>
                        </template>
                        <template v-else-if="membership.giftMode === 'MULTIPLE'">
                            <span class="register-section-title">Kies jouw welkomstcadeau(s) 🎉</span>
                            <span class="register-section-subtitle">Je mag <template v-if="membership.giftMax == null">meerdere</template><template v-else>{{ membership.giftMax }}</template> welkomstcadeaus kiezen bij jouw lidmaatschap! Maak hieronder je keuze.</span>

                            <span class="register-section-subtitle limit-reached" v-if="membership.giftMax != null && giftProductIds.length >= membership.giftMax">Je hebt het maximaal aantal welkomstcadeaus geselecteerd.</span>
                        </template>

                    </div>

                    <div class="multiple-welcome-gifts">

                        <ui-panel
                            v-for="giftProduct of giftProductOptions"
                            padding class="welcome-gift" :class="{
                                selectable: isGiftProductSelectable,
                                selected: isGiftProductSelected(giftProduct.id)
                            }"
                            @click="selectGiftProduct(giftProduct)">
                            <div class="image" :title="giftProduct.title" :style="`background-image: url('${ giftProduct.imageUrl }');`"></div>
                            <div class="content">
                                <span class="title">{{ giftProduct.title }}</span>
                                <div class="rich-text-description" v-html="giftProduct.description"></div>
                                <template v-if="isGiftProductSelectable">
                                    <ui-text-label v-if="isGiftProductSelected(giftProduct.id)" color="primary" class="selected-label">Geselecteerd</ui-text-label>
                                    <ui-text-label v-else color="muted" type="soft" class="selected-label">Niet geselecteerd</ui-text-label>
                                </template>
                            </div>
                        </ui-panel>

                    </div>

                </section>

                <section class="register-section">
                    <div class="register-section-intro">
                        <span class="register-section-title">Jouw gegevens</span>
                        <span class="register-section-subtitle">Vul hieronder jouw gegevens in</span>
                    </div>
                    <ui-panel padding class="user-data">
                        <ui-grid>
                            <div class="col-12">
                                <ui-form-input
                                    placeholder="E-mailadres" label="E-mailadres" name="email" rules="required|email"
                                    v-model="registrationInput.email"
                                    @blur="updatePreRegistration"
                                    :disabled="shouldDisable"
                                />
                            </div>
                            <div class="col-6">
                                <ui-form-input
                                    placeholder="Voornaam" label="Voornaam" name="firstName" rules="required"
                                    v-model="registrationInput.firstName"
                                    @blur="updatePreRegistration"
                                    :disabled="shouldDisable"
                                />
                            </div>
                            <div class="col-6">
                                <ui-form-input
                                    placeholder="Achternaam" label="Achternaam" name="lastName" rules="required"
                                    v-model="registrationInput.lastName"
                                    @blur="updatePreRegistration"
                                    :disabled="shouldDisable"
                                />
                            </div>
                            <div class="col-6">
                                <ui-form-input
                                    placeholder="Straat" label="Straat" name="street" rules="required"
                                    v-model="registrationInput.street"
                                    @blur="updatePreRegistration"
                                    :disabled="shouldDisable"
                                />
                            </div>
                            <div class="col-3">
                                <ui-form-input
                                    placeholder="Huisnummer" label="Huisnummer" name="houseNumber" rules="required" :disabled="shouldDisable"
                                    maxlength="10" v-model="registrationInput.houseNumber" :cleave="{ numeral: true, delimiter: '' }"
                                    @blur="updatePreRegistration"
                                />
                            </div>
                            <div class="col-3">
                                <ui-form-input
                                    placeholder="Toev." label="Toev." name="houseNumberAddition"
                                    v-model="registrationInput.houseNumberAddition" :disabled="shouldDisable"
                                    @blur="updatePreRegistration" rules="max:10"
                                />
                            </div>
                            <div class="col-4">
                                <ui-form-input
                                    placeholder="Postcode" label="Postcode" name="postalCode" rules="required"
                                    v-model="registrationInput.postalCode" :disabled="shouldDisable"
                                    @blur="updatePreRegistration"
                                />
                            </div>
                            <div class="col-8">
                                <ui-form-input
                                    placeholder="Woonplaats" label="Woonplaats" name="city" rules="required"
                                    v-model="registrationInput.city" :disabled="shouldDisable"
                                    @blur="updatePreRegistration"
                                />
                            </div>
                            <div class="col-12">
                                <goh-dropdown 
                                    placeholder="Land" label="Land" name="country" rules="required" searchable
                                    :options="countryOptions" v-model="registrationInput.country"
                                    :disabled="shouldDisable"
                                    @blur="updatePreRegistration" />

                            </div>
                            <div class="col-12">
                                <ui-form-input
                                    placeholder="Telefoonnummer" label="Telefoonnummer" name="phone" rules="required"
                                    v-model="registrationInput.phone" :disabled="shouldDisable"
                                    @blur="updatePreRegistration"
                                />
                            </div>
                        </ui-grid>
                    </ui-panel>
                </section>

                <section class="register-section">
                    <div class="register-section-intro">
                        <span class="register-section-title">Jouw lidmaatschap</span>
                        <span class="register-section-subtitle">Hier zie je precies hoe jouw lidmaatschap eruit ziet</span>
                    </div>
                    <ui-panel padding class="summary-data">
                        <ui-grid>
                            <div class="col-7">
                                <span class="panel-title-compact">{{ membership.title }}</span>
                                <span class="panel-subtitle-compact">{{ $f.paymentDuration(membership.minDuration) }} - {{ $f.paymentFrequency(membership.paymentFrequency) }}</span>
                            </div>
                            <div class="col-5 col-align-right-x">
                                <goh-price
                                    primary black vertical
                                    :price-regular="membership.paymentPrice"
                                    :price-regular-suffix="` ${$f.paymentFrequencySuffix(membership.paymentFrequency)}`"
                                ></goh-price>
                            </div>

                            <template v-if="membership.giftProduct != null">
                                <div class="col-7">
                                    <span class="panel-title-compact">{{ membership.giftProduct.title }}</span>
                                </div>
                                <div class="col-5 col-align-right-x">
                                    <goh-price price-regular-prefix=""></goh-price>
                                </div>
                            </template>

                            <template v-for="selectedCrossProduct of selectedCrossProducts">
                                <div class="col-7">
                                    <span class="panel-title-compact">{{ selectedCrossProduct.title }}</span>
                                </div>
                                <div class="col-5 col-align-right-x">
                                    <goh-price
                                        center
                                        :price-regular="selectedCrossProduct.priceRegular"
                                        :price-discount="selectedCrossProduct.priceDiscount"
                                    ></goh-price>
                                </div>
                            </template>

                            <template v-if="hasShipmentCosts">
                                <div class="col-7">
                                    <span class="panel-title-compact">Verzenden</span>
                                    <span class="panel-subtitle-compact">Eenmalige bijdrage verzendkosten</span>
                                </div>
                                <div class="col-5 col-align-right-x">
                                    <goh-price
                                    center
                                    :price-regular="shipmentCosts"
                                    ></goh-price>
                                </div>
                            </template>
                            

                            <div class="col-12">
                                <hr/>
                            </div>

                            <div class="col-12">
                                <span class="panel-title-compact"><strong>Totaal</strong></span>
                            </div>

                            <div class="col-7">
                                <span class="panel-title-compact-primary">Nu te betalen</span>
                            </div>
                            <div class="col-5 col-align-right-x">
                                <goh-price :price-regular="orderTotal" center primary black></goh-price>
                            </div>

                            <template v-if="membership.discountDuration">
                                <div class="col-7">
                                    <span class="panel-title-compact-regular">Eerste {{ membership.discountDuration }} {{ membership.discountDuration === 1 ? 'maand' : 'maanden' }}</span>
                                </div>
                                <div class="col-5 col-align-right-x">
                                    <goh-price
                                        :price-regular="initialMembershipPrice"
                                        :price-regular-suffix="` ${$f.paymentFrequencySuffix(membership.paymentFrequency)}`"
                                    ></goh-price>
                                </div>
                            </template>

                            <div class="col-7">
                                <span class="panel-title-compact-regular">
                                    <template v-if="membership.discountDuration">{{ $f.discountDuration(membership.discountDuration) }}</template>
                                    <template v-else>Daarna</template>
                                </span>
                            </div>
                            <div class="col-5 col-align-right-x">
                                <goh-price
                                    :price-regular="membership.paymentPrice"
                                    :price-regular-suffix="` ${$f.paymentFrequencySuffix(membership.paymentFrequency)}`"
                                ></goh-price>
                            </div>

                        </ui-grid>
                    </ui-panel>
                </section>

                <section
                    class="register-section"
                    v-if="membership.crossProducts != null && membership.crossProducts.length > 0">

                    <div class="register-section-intro">
                        <span class="register-section-title">Eenmalig speciaal aanbod</span>
                        <span class="register-section-subtitle">Verwen jezelf: dit bestellen onze leden vaak</span>
                    </div>

                    <ui-panel padding class="cross-product" v-for="crossProduct of membership.crossProducts">
                        <div class="cross-product-info">
                            <div
                                class="image" :title="crossProduct.title"
                                :style="`background-image: url('${ crossProduct.imageUrl }');`"
                            ></div>
                            <div>
                                <span class="panel-title-compact">{{ crossProduct.title }}</span>
                                <div class="rich-text-description" v-html="crossProduct.description"></div>
                            </div>
                        </div>
                        <div class="accept-cross-product">
                            <ui-grid>
                                <div class="col-9">
                                    <ui-checkbox
                                        v-model="crossProductIds" :checkboxValue="crossProduct.id"
                                        :disabled="shouldDisable"
                                    >
                                        <span class="register-section-title-primary">Ja graag!</span>
                                        <span class="register-section-title-compact">
                                            Ik bestel '{{ crossProduct.title }}' met korting.
                                        </span>
                                    </ui-checkbox>
                                </div>
                                <div class="col-3 cross-product-price">
                                    <goh-price
                                        vertical primary black
                                        :price-regular="crossProduct.priceRegular"
                                        :price-discount="crossProduct.priceDiscount"
                                    ></goh-price>
                                </div>
                            </ui-grid>
                        </div>
                    </ui-panel>
                </section>

                <section class="register-section payment-method">
                    <div class="register-section-intro">
                        <span class="register-section-title">Kies je betaalmethode</span>
                        <span class="register-section-subtitle">
                            Alle betalingen zijn beveiligd en worden versleuteld door Mollie. We slaan nooit bank of creditcardgegevens op.
                        </span>
                    </div>
                    <ui-panel padding class="payment">
                        <goh-payment-method-selector ref="methodSelector"
                                                     v-model="selectedPayment" :payment-methods="paymentMethods" :disabled="shouldDisable"
                        />
                    </ui-panel>
                </section>

                <section class="register-section accept-terms">
                    <ui-checkbox :disabled="shouldDisable" v-model="acceptTOSAndPrivacyAgreement">
                        <p class="register-section-subtitle">
                            Ik heb de <a @click.prevent="showTOS = !showTOS">algemene voorwaarden</a> en het
                            <a @click.prevent="showPrivacyPolicy = !showPrivacyPolicy">privacybeleid</a> van de website
                            gelezen. Door lid te worden ga ik akkoord met de maandelijkse of jaarlijkse automatische
                            betalingen die bij het lidmaatschap horen. *
                        </p>
                    </ui-checkbox>
                </section>

                <section class="register-section terms" v-if="showTOS">
                    <div class="register-section-intro">
                        <span class="register-section-title">Algemene voorwaarden</span>
                        <ui-button
                            class="register-section-button" type="quiet" icon="bx bx-x" color="neutral"
                            @click="showTOS = false;"
                        ></ui-button>
                    </div>
                    <ui-panel class="scrollable-content">
                        <goh-terms/>
                    </ui-panel>
                </section>

                <section class="register-section terms" v-if="showPrivacyPolicy">
                    <div class="register-section-intro">
                        <span class="register-section-title">Privacybeleid</span>
                        <ui-button
                            class="register-section-button" type="quiet" icon="bx bx-x" color="neutral"
                            @click="showPrivacyPolicy = false"
                        ></ui-button>
                    </div>
                    <ui-panel class="scrollable-content">
                        <goh-privacy-policy/>
                    </ui-panel>
                </section>

                <div class="goh-error-block" v-if="pendingTransaction?.paymentError">
                    <span class="title">De betaling is mislukt.</span>
                    <p>{{ this.$f.paymentError(pendingTransaction.paymentError) }}</p>
                </div>

                <section class="register-section submit">
                    <ui-button
                        color="primary" :loading="submittingRegistration || pendingTransaction?.state === 'PENDING'"
                        @click="$refs.registerForm.submit()">
                        Ik wil nu lid worden!
                    </ui-button>
                </section>

            </ui-form>

            <p v-else-if="!loading">Het registratieformulier kon niet worden ingeladen. Probeer het later nog een keer...</p>
        </div>
    </goh-widget>

</template>

<script>

import { Vue, Options } from 'vue-class-component';

import { createQuery, getGraphQLError } from '@wittignl/js-core';
import { Button, Form, FormInput, FormDropdown, Grid, Panel, Checkbox, TextLabel } from '@wittignl/js-ui';

import Price from '../../component/ui/Price.vue';
import Terms from '../../component/ui/Terms.vue';
import Dropdown from '../../component/ui/Dropdown.vue';
import PrivacyPolicy from '../../component/ui/PrivacyPolicy.vue';
import PaymentMethodSelector from '../../component/ui/PaymentMethodSelector.vue';

import Widget from './Widget.vue';

import ApiErrorType from 'common/js/enum/ApiErrorType';
import TransactionState from 'common/js/enum/TransactionState';
import MembershipGiftMode from 'common/js/enum/MembershipGiftMode';
import { getCountryOptions } from 'common/js/util/country';
import { emailRegex } from 'common/js/regex';

import PreRegistrationMutations from '../../mutation/PreRegistrationMutations';
import FindMembership from '../../../gql/query/Register/FindMembership.gql';
import FindPaymentMethods from '../../../gql/query/Register/FindPaymentMethods.gql';
import FindTransactionByToken from '../../../gql/query/Register/FindTransactionByToken.gql';
import FindPreregistration from '../../../gql/query/Register/FindPreregistration.gql';
import PaymentMethod from 'common/js/enum/PaymentMethod';
import EmailAccountType from "../../auth/EmailAccountType";
import { getShipmentCosts } from 'common/js/util/shipment';

const PENDING_TOKEN_KEY = 'GOH_PENDING_TOKEN'

export default Options({
    name: 'goh-register',
    components: {
        [Widget.name]: Widget,
        [Button.name]: Button,
        [Form.name]: Form,
        [FormInput.name]: FormInput,
        [FormDropdown.name]: FormDropdown,
        [Grid.name]: Grid,
        [Panel.name]: Panel,
        [TextLabel.name]: TextLabel,
        [Checkbox.name]: Checkbox,
        [Price.name]: Price,
        [Terms.name]: Terms,
        [PrivacyPolicy.name]: PrivacyPolicy,
        [PaymentMethodSelector.name]: PaymentMethodSelector,
        [Dropdown.name]: Dropdown
    },

    apollo: {

        membershipResponse: createQuery(FindMembership, {

            variables(){

                return {
                    id: this.membershipId
                };
            },

            skip(){
                return !this.membershipId;
            },

            response(membership){

                if (membership == null) {

                    this.handleMembershipError();
                    return;
                }

                this.membership = membership;

                if(membership.giftMode === MembershipGiftMode.SINGLE && membership.giftProducts.length && !this.giftProductIds.length){
                    this.giftProductIds.push(membership.giftProducts[0].productId);
                }
            },

            error() {

                this.handleMembershipError();
            }
        }),

        recoveryPreRegistration: createQuery(FindPreregistration, {

            variables(){

                return {
                    token: this.recoveryToken
                }
            },

            skip(){
                return !this.recoveryToken;
            },

            response({
                id, membership, giftProducts,
                firstName, lastName,
                email, phone,
                street, houseNumber, houseNumberAddition,
                postalCode, city, country
            }){

                this.preRegistration = {

                    preregistrationId: id,
                    token: this.recoveryToken
                };

                this.membership = membership;

                this.giftProductIds = giftProducts.map(gp => gp.id);

                Object.assign(this.registrationInput, {

                    firstName, lastName,
                    email, phone,
                    street, houseNumber, houseNumberAddition,
                    postalCode, city, country
                });
            }
        }),

        paymentMethods: createQuery(FindPaymentMethods),

        pendingTransaction: createQuery(FindTransactionByToken, {

            loadingKey: 'loadingTransaction',

            variables(){

                return {
                    token: this.token
                };
            },

            skip(){
                return !this.token;
            },

            response(transaction){

                const thanksUrl = transaction.subscription?.membership.thanksUrl;
                const pendingToken = window.sessionStorage.getItem(PENDING_TOKEN_KEY);

                if(transaction.state === TransactionState.COMPLETED && thanksUrl && pendingToken){

                    this.redirectToLoginFlow(JSON.parse(pendingToken), thanksUrl);
                }

                if(transaction.user == null || transaction.subscription?.membership == null){
                    return;
                }

                this.membership = transaction.subscription.membership;

                this.giftProductIds = transaction.subscription.giftProducts.map(gp => gp.id);
                this.crossProductIds = transaction.subscription.crossProducts.map(cp => cp.id);
                this.registrationInput = {

                    firstName: transaction.user.firstName,
                    lastName: transaction.user.lastName,

                    email: transaction.user.email,
                    phone: transaction.user.phone,

                    street: transaction.user.street,
                    houseNumber: transaction.user.houseNumber,
                    houseNumberAddition: transaction.user.houseNumberAddition,

                    postalCode: transaction.user.postalCode,
                    city: transaction.user.city,
                    country: transaction.user.country
                };

                this.selectedPayment = {

                    method: transaction.paymentMethod,
                    issuer: transaction.paymentIssuer
                };

                switch(transaction.state){

                    case TransactionState.PENDING:
                        setTimeout(() => this.$apollo.queries.pendingTransaction.refetch(), 1000);
                        break;

                    case TransactionState.FAILED:

                        if(!this.didScrollToBottom){
                            this.scrollToBottom();
                        }
                        break;
                }
            }
        })
    }
})(
    class Register extends Vue {

        showTOS = false;
        showPrivacyPolicy = false;

        membershipId = null;
        membership = null;
        token = null;
        recoveryToken = null;

        didScrollToBottom = false;

        registrationInput = {

            firstName: null,
            lastName: null,

            email: null,
            phone: null,

            street: null,
            houseNumber: null,
            houseNumberAddition: null,

            postalCode: null,
            city: null,
            country: null
        };

        selectedPayment = null;
        crossProductIds = [];
        giftProductIds = [];
        preRegistration = null;
        acceptTOSAndPrivacyAgreement = false;

        loading = 0;
        loadingTransaction = 0;
        submittingRegistration = false;
        preRegistering = false;
        shouldUpdatePreRegistration = false;
        forceShowHiddenGiftProducts = false;

        utmCampaign = null;
        utmContent = null;
        utmMedium = null;
        utmSource = null;
        utmTerm = null;

        get giftProductOptions(){
            return this.membership.giftProducts
                .filter(mgp => this.forceShowHiddenGiftProducts || mgp.hidden === false)
                .map(mgp => mgp.product)
                ;
        }

        get countryOptions(){

            return getCountryOptions(this.$f.country);
        }

        get utmData(){

            return {

                campaign: this.utmCampaign,
                content: this.utmContent,
                medium: this.utmMedium,
                source: this.utmSource,
                term: this.utmTerm
            };
        }

        get submitRegistrationDisabled(){

            return !this.acceptTOSAndPrivacyAgreement;
        }

        get selectedCrossProducts(){

            const searchmap = Object.fromEntries(
                this.membership.crossProducts.map(product => [`cp-${product.id}`, product])
            );

            return this.crossProductIds?.map(id => searchmap[`cp-${id}`]) || [];
        }

        get selectedGiftProducts(){

            const searchmap = Object.fromEntries(
                this.membership.giftProducts.map(({ product }) => [`gp-${product.id}`, product])
            );

            return this.giftProductIds?.map(id => searchmap[`gp-${id}`]) || [];
        }

        get orderTotal(){

            const productPrices = this.selectedCrossProducts.reduce((acc, scp) => acc + (scp.priceDiscount != null ? scp.priceDiscount : scp.priceRegular), 0);

            let price = this.initialMembershipPrice + productPrices;

            if(this.hasShipmentCosts) {
                price += this.shipmentCosts;
            }

            return price;
        }

        get initialMembershipPrice(){

            let amount = this.membership.paymentPrice;

            if(this.membership.discountDuration){

                if(this.membership.discountFixed){
                    amount -= this.membership.discountFixed;
                }

                if(this.membership.discountPercentage){
                    amount -= this.membership.paymentPrice * (this.membership.discountPercentage / 100);
                }
            }

            return amount;
        }

        get shouldDisable(){

            return this.loading > 0 || this.pendingTransaction?.state === TransactionState.PENDING;
        }

        get isGiftProductSelectable(){

            return [MembershipGiftMode.MULTIPLE, MembershipGiftMode.SINGLE_CHOICE].includes(this.membership.giftMode);
        }

        get isForeignOrder() {

            return this.registrationInput.country != null && this.registrationInput.country !== 'NL';
        }

        get hasShipmentCosts() {

            return this.isForeignOrder && (this.selectedCrossProducts.length > 0 || this.selectedGiftProducts.length > 0);
        }

        get shipmentCosts() {

            return getShipmentCosts();
        }

        isGiftProductSelected(productId){

            return this.isGiftProductSelectable && this.giftProductIds.includes(productId);
        }

        created(){

            let urlParams = new URLSearchParams(window.location.search);

            this.token = urlParams.get('token');
            this.membershipId = urlParams.get('membership_id');
            this.utmCampaign = urlParams.get('utm_campaign');
            this.utmContent = urlParams.get('utm_content');
            this.utmMedium = urlParams.get('utm_medium');
            this.utmSource = urlParams.get('utm_source');
            this.utmTerm = urlParams.get('utm_term');

            this.recoveryToken = urlParams.get('recover');

            if(urlParams.get('hidden') === 'true'){
                this.forceShowHiddenGiftProducts = true;
            }
        }

        mounted(){

            // if(this.membershipId == null || this.token == null){
            //
            //     window.location.replace('/');
            // }
        }

        selectGiftProduct(product){

            const alreadySelected = this.giftProductIds.includes(product.id);
            if (!alreadySelected) {
                const maxGifts = this.membership.giftMax;
                if (maxGifts != null && this.giftProductIds.length >= maxGifts) {
                    return;
                }
            }

            switch(this.membership.giftMode){

                case MembershipGiftMode.SINGLE_CHOICE:
                    this.giftProductIds = (alreadySelected == true ? [] : [product.id]);
                    break;

                case MembershipGiftMode.MULTIPLE:
                    if(alreadySelected){
                        this.giftProductIds = this.giftProductIds.filter(id => id != product.id);
                    }
                    else {
                        this.giftProductIds.push(product.id);
                    }
                    break;
            }

            this.updatePreRegistration()
        }

        async redirectToLoginFlow(pendingToken, thanksUrl){

            await this.$auth.login(EmailAccountType.NAME, {
                token: pendingToken
            });

            window.sessionStorage.removeItem(PENDING_TOKEN_KEY);

            const redirectUrl = new URL(this.$oauthRedirectUrl);
            redirectUrl.searchParams.set('redirect_url', thanksUrl);

            window.location.href = redirectUrl.toString();
        }

        async preRegister(){

            this.preRegistering = true;

            try {

                const { data } = await PreRegistrationMutations.preRegister(this.$apollo.getClient(), {

                    input: {

                        firstName: this.registrationInput.firstName,
                        lastName: this.registrationInput.lastName,
                        email: this.registrationInput.email,

                        membershipId: this.membershipId,

                        utm: this.utmData,

                        giftProductIds: this.giftProductIds
                    }
                });

                this.preRegistration = data?.preregister || null;
            }
            catch(e){

                this.logger.error('Could not pre-register user:', e);
            }

            this.preRegistering = false;
        }

        async updatePreRegistration(){

            // Block invalid pre-registrations
            if(this.registrationInput.email == null || !this.registrationInput.email?.match(emailRegex) || this.pendingTransaction){
                return;
            }

            // If already pre-registering... queue an update.
            if(this.preRegistering === true){
                this.shouldUpdatePreRegistration = true;
                return;
            }

            // If no pre-registration is found, create one...
            if(this.preRegistration == null){

                await this.preRegister();
                return;
            }

            // Update pre-registration
            try {

                this.preRegistering = true;

                await PreRegistrationMutations.updatePreRegistration(this.$apollo.getClient(), {
                    preregistrationId: this.preRegistration.preregistrationId,
                    token: this.preRegistration.token,
                    input: {
                        ...this.registrationInput,
                        giftProductIds: this.giftProductIds
                    }
                });

                this.preRegistering = false;
            }
            catch(e){

                this.logger.error('Could not update pre-registration:', e);
            }

            // If an update was queued in the meantime, perform it.
            if(this.shouldUpdatePreRegistration === true){

                this.shouldUpdatePreRegistration = false;

                this.$nextTick(async () => {

                    await this.updatePreRegistration();
                });
            }
        }

        async submitRegistration(){

            // if(this.registrationInput.country == null) {

            //     this.$toast.warning('Kies een land om verder te gaan.');
            //     return;
            // }

            if(this.submitRegistrationDisabled){

                this.$toast.warning('Accepteer de algemene voorwaarden en het privacybeleid om verder te gaan.');
                return;
            }

            if(this.isGiftProductSelectable && !this.giftProductIds.length){

                this.$toast.warning('Je hebt nog geen welkomstcadeau gekozen, klik op het gewenste welkomstcadeau.');
                return;
            }

            if(this.selectedPayment?.method == null){

                this.$toast.warning('Je hebt nog geen betaalmethode gekozen, kies de gewenste betaalmethode.');
                return;
            }

            if(!this.$refs.methodSelector.validate()){
                this.$toast.validation();
                return;
            }

            this.submittingRegistration = true;

            try {

                const cardToken = await this.$mollie.createToken();

                const registrationResponse = (
                    (
                        await PreRegistrationMutations.register(this.$apollo.getClient(), {

                            preregistrationToken: this.preRegistration?.token || null,
                            transactionToken: this.token || null,

                            input: this.registrationInput,

                            crossProductIds: this.crossProductIds,
                            giftProductIds: this.giftProductIds,

                            payment: {
                                ...(this.selectedPayment?.method === PaymentMethod.CREDIT_CARD ? { cardToken: cardToken.token } : {}),
                                ...(
                                    this.selectedPayment || {}
                                )
                            }
                        })
                    )
                        ?.data
                        ?.register
                );

                window.sessionStorage.setItem(PENDING_TOKEN_KEY, JSON.stringify(registrationResponse.token));

                window.location.replace(registrationResponse.paymentUrl);
            }
            catch(e){

                console.error(e);
                const error = getGraphQLError(e);

                switch(error?.type){

                    case ApiErrorType.USER_DUPLICATE:
                        this.$toast.error('Er bestaat al een account met dit e-mailadres, log in met je bestaande account of gebruik een ander e-mailadres.');
                        break;

                    default:
                        this.$toast.error('Er is een onverwachtse fout opgetreden. Probeer het later nog een keer.');
                        break;
                }
            }

            this.submittingRegistration = false;
        }

        scrollToBottom(){

            this.$nextTick(() => {

                this.$el.scrollIntoView({
                    block: 'end',
                    inline: 'nearest'
                });
            });

            this.didScrollToBottom = true;
        }

        handleMembershipError() {

            if (!this.$auth.loggedIn) {

                return;
            }

            this.$auth.logout();
            window.location.reload();
        }
    }
);

</script>
<style src="../../../scss/component/widget/Register.scss" lang="scss"></style>