export default {

    i18n: {
        languages: ['nl'],
        defaultLanguage: 'nl'
    },

    mollie: {
        profile: 'pfl_qD9hzMB2s5'
    }
}