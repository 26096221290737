<template>

   <a href="#">
       <slot></slot>
   </a>

</template>

<script>

import { Options, Vue } from 'vue-class-component';

export default Options({
    name: 'router-link'
})(
    class RouterLinkShim extends Vue {


    }
);

</script>