import { AuthenticationPlugin, createAuthenticationLink, createRefreshTokenLink, createRegexValidator } from "@wittignl/js-core";
import { spinnerDirective, alertMixin, tooltipDirective } from "@wittignl/js-ui";

import store from "../store"
import * as components from "../component"

import { defineRule, configure as configureValidation } from 'vee-validate';
import { required, email } from '@vee-validate/rules';

import axios from "axios";

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";

import EmailAccountType from "../auth/EmailAccountType";
import { emailRegex, passwordRegex } from '../../../../../../common/js/regex';


export interface GekOpHakenPluginOptions {

    apiUrl: string,
    oauthAuthorizeUrl: string,
    oauthRedirectUrl: string,

    mollie: any,
    i18n: any
}

export const gekOpHakenPlugin = {

    install(app, options: GekOpHakenPluginOptions){

        // Mollie
        app.config.globalProperties.$mollie = options.mollie;
        app.config.globalProperties.$oauthAuthorizeUrl = options.oauthAuthorizeUrl;
        app.config.globalProperties.$oauthRedirectUrl = options.oauthRedirectUrl;

        // Create store
        app.use(store);

        // Authentication
        const auth = new AuthenticationPlugin({
            sessionKey: 'session-client'
        });

        app.use(auth);

        // HTTP
        const http = axios.create({
            baseURL: options.apiUrl
        });

        // HTTP Interceptor
        http.interceptors.request.use(request => {

            let session = auth.session;

            if(session) {

                return {
                    ...request,
                    headers: {
                        Authorization: `Bearer ${session.token}`
                    }
                }
            }

            return request;
        });

        // Apollo
        const httpLink = createHttpLink({
            uri: options.apiUrl + "/graphql/app"
        });

        const link = createRefreshTokenLink(auth)
            .concat(createAuthenticationLink(auth))
            .concat(httpLink);

        const cache = new InMemoryCache();

        const apolloClient = new ApolloClient({

            link, cache
        });

        // Auth Hooks
        auth.on("logout", async () => {
            await apolloClient.cache.reset();
        });

        // VueApollo
        const vueApollo = createApolloProvider({
            defaultClient: apolloClient
        });

        app.use(vueApollo);

        // Restore Session
        auth.accountType(EmailAccountType.NAME, new EmailAccountType(apolloClient, http, options.apiUrl));
        auth.restoreSession();

        // Configure validation
        defineRule('required', required);
        defineRule('email', createRegexValidator(emailRegex));

        configureValidation({

            generateMessage: context => {

                const i18n = options.i18n.global

                let paramIndex = 1;
                let params = {};

                for(let param of context.rule?.params || []){

                    params[`param${paramIndex}`] = param;
                    paramIndex++;
                }

                return i18n.t(`validation.${context.rule.name}`, { field: context.field, ...params });
            }
        });

        // Register directives
        app.directive('spinner', spinnerDirective);
        app.directive('tooltip', tooltipDirective);

        // Register components
        for(let comp of Object.values(components)){
            app.component(comp.name, comp);
        }

        app.mixin(alertMixin);

        // Add check mixin
        app.mixin({

            mounted(){

                if(!this.$i18n){
                    throw 'GekOpHaken plugin requires i18n plugin to be registered ($i18n not available)'
                }

                if(!this.$overlay){
                    throw 'GekOpHaken plugin requires Overlay plugin to be registered ($overlay not available)'
                }

                if(!this.$toast){
                    throw 'GekOpHaken plugin requires Toast plugin to be registered ($toast not available)'
                }
            }
        });
    }
}