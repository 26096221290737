import config from '@/js/bootstrap/config'
import logger from '@/js/bootstrap/logger'
import plugins from '@/js/bootstrap/plugins'
import components from '@/js/bootstrap/components'
import directives from '@/js/bootstrap/directives'
import i18n from '@/js/bootstrap/i18n'
import filters from '@/js/bootstrap/filters'
import mixins from '@/js/bootstrap/mixins'
import validation from '@/js/bootstrap/validation'
import services from '@/js/bootstrap/services'

export default function bootstrap(app){

    config(app);
    logger(app);
    components(app);
    directives(app);
    i18n(app);
    filters(app);
    mixins(app);
    validation(app);
    services(app);
    plugins(app);
}